import React, { FunctionComponent, useCallback } from 'react';
import { useStoreState } from 'easy-peasy';
import classnames from 'classnames';

import { Timer } from './Timer';
import { usePlayers, useCurrentPlayer, Player } from '../../state';
import { useKarolinaLevel } from '../../levels/Level4/state';

import stefan from './assets/stefanFace.jpg';
import ally from './assets/allyFace.jpg';
import hudTeam1 from './assets/hudTeam1.png';
import hudTeam2 from './assets/hudTeam2.png';

import style from './GameBoard.module.css';

export const GameBoard: FunctionComponent<{}> = (props) => {
  const [{ currentPlayer }] = useCurrentPlayer();
  const { data: players } = usePlayers();
  const [{ power }] = useKarolinaLevel();

  const isBlueTeam = currentPlayer.team === 'blue';
  const modalOpen = useStoreState(state => state.app.modalOpen);
  const level = currentPlayer?.level;

  const renderPlayer = useCallback((player: Player) => (
    <li key={player.id}>
      {player.name ? player.name : '<Unnamed player>'}{' '} {player.id === currentPlayer.id ? '(You)' : ''}
    </li>
    // eslint-disable-next-line
  ), [currentPlayer]);

  const TopTeam = useCallback(() => (
    <div className={style.detectiveContainer}>
      {isBlueTeam
        ? (
          <img
            className={style.player}
            src={stefan}
            alt="Stefan"
          />
        ) : (
          <img
            className={style.player}
            src={ally}
            alt="Ally"
          />
        )}
      <div className={style.hudContainer1} style={{ backgroundImage: `url(${hudTeam1})` }}>
        <span className={style.detectiveName1}>{isBlueTeam ? 'Old Dog' : 'Ally'}</span>
        <div className={style.playerList1}>
          <ul>
            {players?.filter(p => p.team === currentPlayer.team).map(renderPlayer)}
          </ul>
        </div>
      </div>
    </div>
    // eslint-disable-next-line
  ), [isBlueTeam, players, renderPlayer]);

  const BottomTeam = useCallback(() => (
    <div className={style.detectiveContainer2} style={{ backgroundImage: `url(${hudTeam2})` }}>
      <span className={style.detectiveName2}>{!isBlueTeam ? 'Old Dog' : 'Ally'}</span>
      <div className={style.playerList2}>
        <ul>
          {players?.filter(p => p.team === (isBlueTeam ? 'red' : 'blue')).map(renderPlayer)}
        </ul>
      </div>
    </div>
    // eslint-disable-next-line
  ), [isBlueTeam, players, renderPlayer]);

  const level2Background = isBlueTeam ? style.level2BackgroundBlue : style.level2BackgroundRed;
  const level3Background = isBlueTeam ? style.level3BackgroundBlue : style.level3BackgroundRed;
  let level4Background = isBlueTeam ? style.level4BackgroundBlue1 : style.level4BackgroundRed1;
  if (power) {
    level4Background = isBlueTeam ? style.level4BackgroundBlue2 : style.level4BackgroundRed2;
  }
  const level5Background = isBlueTeam ? style.level5BackgroundBlue : style.level5BackgroundRed;

  return (
    <div
      className={classnames(
        level === '1' ? style.level1Background : '',
        level === '2' ? level2Background : '',
        level === '3' ? level3Background : '',
        level === '4' ? level4Background : '',
        level === '5' ? level5Background : '',
        ['1', '2', '3', '4', '5'].indexOf(level) !== -1 ? style.blackBackground : '',
        modalOpen ? style.blur : ''
      )}
    >
      <div className={classnames(style.wrapper)}>
        <div className={style.gameBoard}>
          {props.children}
        </div>
        <div className={style.stats}>
          <Timer />
          <div>
            <TopTeam />
            <BottomTeam />
          </div>
        </div>
      </div>
    </div>
  );
};
