import { useCallback } from 'react';
import { db, useFirestoreQuery } from '../../firebase';
import { useAccessCode, useCurrentPlayer } from '../../state';

export interface BombState {
  r: number; // red wire
  w: number; // white wire
  g: number; // green wire
  b: number; // blue wire
}

export const bombInitialState: BombState = {
  r: 0,
  w: 0,
  g: 0,
  b: 0
};

type BombLevelResult = [BombState, {
  setWires: (wires: BombState, team?: string) => void;
  resetBombSequence: () => void;
}];

export function useBombLevel(): BombLevelResult {
  const [{ code, loading: codeLoading }] = useAccessCode();
  const [{ currentPlayer: { team }, loading: playerLoading }] = useCurrentPlayer();
  const loading = codeLoading || playerLoading;

  const { data } = useFirestoreQuery<BombState>(
    !loading && db.collection('game')
      .doc(code)
      .collection('bombLevel')
      .doc(team)
  );

  const state = { ...bombInitialState, ...(data || {}) };

  function setWires(wires: Partial<BombState>, tim?: string) {
    db.collection('game')
      .doc(code)
      .collection('bombLevel')
      .doc(tim || team)
      .set(wires, { merge: true });
  }

  const resetBombSequence = useCallback(() => {
    // reset wires
    // db.collection('game')
    //   .doc(code)
    //   .collection('bombLevel')
    //   .doc('red')
    //   .set(bombInitialState, { merge: true });

    // db.collection('game')
    //   .doc(code)
    //   .collection('bombLevel')
    //   .doc('blue')
    //   .set(bombInitialState, { merge: true });

    // reset bomb timer
    db.collection('game')
      .doc(code)
      .set({
        bombArmed: false,
        bombStartedAt: 0
      }, { merge: true });

    // reset waveform
    db.collection('game')
      .doc(code)
      .collection('waveform')
      .doc('state')
      .delete();
  }, [code]);

  return [state, { setWires, resetBombSequence }];
}
