import React from 'react';
import { Tooltip } from 'antd';

import style from './ConnectedIcon.module.css';

interface ConnectedIconProps {
  noLight?: boolean;
  style?: React.CSSProperties;
}

export const ConnectedIcon = (props: ConnectedIconProps) => (
  <div className={style.wrapper} style={{ ...props.style }}>
    <Tooltip title="This device is connected with something... Could it be another device?">
      <div>
        {!props.noLight && <span className={style.indicator}>{' '}</span>}
        <span className={style.label}>Connected</span>
      </div>
    </Tooltip>
  </div>
);
