import React, { useState } from 'react';
import { message } from 'antd';
import globalHook from 'use-global-hook';
import Cookies from 'universal-cookie';
import { db, useFirestoreQuery } from '../firebase';
import { logger } from '../utils/logger';

const cookies = new Cookies();

type AccessCode = {
  code: string;
  company?: string;
  team?: string;
}

type AccessCodeResult = [{
  loading: boolean;
  code: string;
  data?: AccessCode;
}, {
  setCode: (code: string) => void;
  isCorp: () => boolean;
}];

const useGlobal = globalHook<any, any>(React, { code: cookies.get('accessCode') || '' }, {
  setCode: (store: any, code: any) => {
    store.setState({ code });
  }
});

export function useFirestoreAccessCode() {
  const [{ code }] = useGlobal();
  return useFirestoreQuery<AccessCode>(
    db.collection('accessCode')
      .doc(code || '-')
  );
}

export function useAccessCode(): AccessCodeResult {
  const [{ code }, { setCode: setGlobalCode }] = useGlobal();
  const [loading, setLoading] = useState(false);
  const { data } = useFirestoreAccessCode();

  const setCode = (newCode: string) => {
    const newCodeSanitized = newCode.trim().toLowerCase();
    setLoading(true);
    // determine if access code is valid
    db.collection('accessCode')
      .doc(newCodeSanitized)
      .get()
      .then((doc) => {
        const docData = doc.data();
        if (docData) {
          setGlobalCode(newCodeSanitized);
          cookies.set('accessCode', newCodeSanitized, {
            path: '/',
            maxAge: 60 * 60 * 3 // in seconds
          });
        } else {
          message.error('Access code not found');
          logger.error({
            message: 'Access code not found',
            accessCode: newCodeSanitized
          });
        }
      })
      .catch((err) => {
        message.error('Unknown error occurred, please try again');
        logger.error({
          message: 'Unknown error occurred',
          err
        });
        try {
          (window?.Rollbar).critical('Unknow error when adding a code', { err: JSON.stringify(err) });
        } catch (rollbarError) {
          console.error(rollbarError);
        }
      })
      .finally(() => setLoading(false));
  };

  const isCorp = () => !!data?.company;

  return [{ code, data, loading }, { setCode, isCorp }];
}
