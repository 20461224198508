import React, { useEffect } from 'react';
import { StoreProvider, useStoreActions } from 'easy-peasy';
import { DndProvider } from 'react-dnd';
// import { DndProvider } from 'react-dnd-multi-backend';
// import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
// import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { withResizeDetector } from 'react-resize-detector';

import AccessCode from './waiting/AccessCode';
import store from './store';
import './App.css';

const App = ({ width, height }: { width: number; height: number }) => {
  const setSize = useStoreActions((state: any) => state.resize.set);

  useEffect(() => {
    setSize({ height, width });
  }, [height, width, setSize]);

  return (
    <div className="App">
      <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
        <AccessCode />
      </DndProvider>
    </div>
  );
};

const AppWithResize = withResizeDetector(App);

export default () => (
  <StoreProvider store={store}>
    <AppWithResize />
  </StoreProvider>
);
