import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useStoreActions } from 'easy-peasy';

import { useCurrentPlayer, useGame } from '../../state';
import { GameItem, sounds, speech } from '../../components';
import { Puzzle, puzzleImages } from './Puzzle';
import { Bomb, bombImages } from './Bomb';
import { Slider, sliderImages } from './Slider';
import { Waveform, waveformImages } from './Waveform';
import { useWaveform } from './Waveform/state';

import style from './BombLevel.module.css';

import whereIsThePaint from './img/whereIsThePaint.png';
import splatter from './img/splatter.png';
import musicNotes from './img/musicNotes.png';
import musicPaper from './img/musicPaper.png';
import flagSystem from './img/flagSystem.png';
import escapeDirections from './img/escapeDirections.png';
import badges from './img/badges.png';

export const bombLevelImages = [
  '/img/l5Red.jpg',
  '/img/l5Blue.jpg',
  whereIsThePaint,
  splatter,
  musicNotes,
  musicPaper,
  flagSystem,
  escapeDirections,
  badges,
  ...bombImages,
  ...puzzleImages,
  ...sliderImages,
  ...waveformImages
];

const musicPaperHints = [
  'To solve this puzzle, you will need this piece of music and possibly the music piece in the other box, depending on your ability to read music.',
  'Using the other piece of music, you should be able to determine the notes on this piece of music. What do they spell out? Also, note the words under the music piece.',
  'If translated correctly, the music notes should read B-A-D-B-A-D-G-E, ring a bell?',
  'Bad badge should lead you to a number on one of the badges; 8 is the number you are looking for. The words underneath the music piece refer to the wire that you need to use that number on (blue).'
];

const musicNotesHints = [
  'You need the music piece in the red box to solve this puzzle. You may or may not need this for the puzzle, depending on your ability to read music.',
  'This piece of the puzzle is exclusively here to help with reading music found in the red box.',
  'If you need more help, see the clues on the other music piece.'
];

const badgesHints = [
  'This puzzle requires the music piece from Ally’s team and possibly the music piece from Old Dog’s team.',
  'If you need more clues to these badges, complete the music piece puzzles first (use the clues if you are totally stuck), and that should give you all the information you need here.'
];

const splatterHints = [
  'This puzzle requires the painted wooden fish from Ally’s team and the painted words from Old Dog’s team\'s box to complete.',
  'Relay your colours to Old Dog’s team, and they should be able to help considerably.',
  'The colours are red-green-blue-purple-purple.'
];

const whereIsThePaintHints = [
  'This puzzle requires the painted words here plus the painted wooden fish found in Ally’s team\'s box.',
  'Notice that each letter is a different colour. That information may be useful to Old Dog’s team.',
  'Red – T, Green – H, Blue – R, Purple – E, Purple – E, Spells out THREE. This is the voltage of one of the wires for the bomb.'
];

const escapeDirectionsHints = [
  'This puzzle requires the weekly wind prediction and the semaphore alphabet and compass found in Old Dog’s team\'s box.',
  'These wind directions will be useful for Ally’s team to know. Take them as daily and give them both directions listed for each day.',
  'Any more clues will need to come from Ally’s team\'s puzzle!!'
];

const flagSystemHints = [
  'This puzzle requires the semaphore alphabet and compass from your box and the weekly wind directions in Old Dog’s team\'s box.',
  'You could relay the information for each semaphore letter to the other team as compass directions or vice versa (definitely vice versa…).',
  'Using the wind directions per day you should be able to get a letter, rinse and repeat until you have all 7 letters.',
  'The letters should spell out REDFIVE indicating that the voltage for the red wire is 5.'
];

export const BombLevel = () => {
  const [{ currentPlayer: { team } }] = useCurrentPlayer();
  const isBlueTeam = team === 'blue';
  const [{ data }] = useGame();
  const setModalVisible = useStoreActions((state: any) => state.app.setModalOpen);
  const [{ visible, loading }, { showWaveformDevice }] = useWaveform();
  const [attention, setAttention] = useState(false); // for the waveform
  const bombArmed = data?.bombArmed || 0;

  const loadingRef = useRef(loading);
  useEffect(() => {
    const wasLoading = loadingRef.current;
    loadingRef.current = loading;
    if (loading) return;

    if (!wasLoading && bombArmed && !visible) {
      // sounds.alarm();
      setTimeout(() => {
        if (isBlueTeam) {
          speech.say('stefanLevel5BombChange1');
        } else {
          speech.say('allyLevel5BombChange2');
        }
        setTimeout(() => speech.say('ckLevel5BombChange1'), 3500);
        setModalVisible(false);
        if (visible) return;
        setTimeout(() => {
          showWaveformDevice();
        }, 1000);
      }, 4500);
    }
    // eslint-disable-next-line
  }, [bombArmed, visible, loading]);

  const demandAttention = useCallback(() => {
    if (!attention) {
      setAttention(true);
      setTimeout(() => sounds.wobble(), 300);
      setTimeout(() => setAttention(false), 1000);
    } else {
      sounds.error();
    }
  }, [attention]);

  return (
    <div className={style.bombvel}>
      <Puzzle
        disabled={!!bombArmed}
        onClick={() => bombArmed && demandAttention()}
        speech={isBlueTeam ? 'stefanLevel5JigaswPuzzle' : 'allyLevel5JigaswPuzzle'}
      />
      <Bomb onDisabledClick={() => demandAttention()} />
      <Slider
        disabled={!!bombArmed}
        onClick={() => bombArmed && demandAttention()}
        speech={isBlueTeam ? 'stefanLevel5SlidingPuzzle' : 'allyLevel5SlidingPuzzle'}
      />
      <Waveform attention={attention} />
      <GameItem
        disabled={!!bombArmed}
        onClick={() => bombArmed && demandAttention()}
        speech={isBlueTeam ? 'l5PaintedLetters' : 'l5FishSupper'}
        iconStyle={
          isBlueTeam
            ? { left: '0%', top: '0%', width: '36%', height: '23%' }
            : { left: '2%', top: '0%', width: '33%', height: '33%' }
        }
        imageSrc={isBlueTeam ? whereIsThePaint : splatter}
        canvas={true}
        name={isBlueTeam ? 'l5WhereIsThePaint' : 'l5Splatter'}
        hints={isBlueTeam ? whereIsThePaintHints : splatterHints}
        description={isBlueTeam ? 'Some painted word of varying colours, very artistic!' : 'A nicely painted wooden fish!'}
      />
      <GameItem
        disabled={!!bombArmed}
        onClick={() => bombArmed && demandAttention()}
        speech={isBlueTeam ? 'l5MusicPiece' : 'l5MusicPiece'}
        iconStyle={
          isBlueTeam
            ? { left: '75%', top: '6%', width: '20%', transform: 'rotate(10deg)', height: '23%' }
            : { left: '75%', top: '7%', width: '25%', transform: 'rotate(10deg)', height: '20%' }
        }
        imageSrc={isBlueTeam ? musicNotes : musicPaper}
        canvas={true}
        name={isBlueTeam ? 'musicNotes' : 'musicPaper'}
        hints={isBlueTeam ? musicNotesHints : musicPaperHints}
        description={isBlueTeam ? 'Looks like a bar of music, what does it mean?' : 'An excerpt from a music piece'}
      />
      <GameItem
        disabled={!!bombArmed}
        onClick={() => bombArmed && demandAttention()}
        speech={isBlueTeam ? 'l5windPuzzleSemaphore' : 'l5WindPuzzleWindDirections'}
        iconStyle={
          isBlueTeam
            ? { left: '1.7%', top: '70%', width: '20%', height: '29%', transform: 'rotate(-11deg)' }
            : { left: '2.2%', top: '73%', width: '20%', height: '28.5%', transform: 'rotate(-11deg)' }
        }
        imageSrc={isBlueTeam ? flagSystem : escapeDirections}
        canvas={true}
        name="l5Newspaper1"
        hints={isBlueTeam ? flagSystemHints : escapeDirectionsHints}
        description={isBlueTeam ? 'Semaphores and a compass? Am I going sailing?' : 'A weekly prediction? For what? What does this mean?'}
      />
      {isBlueTeam && (
        <GameItem
          disabled={!!bombArmed}
          onClick={() => bombArmed && demandAttention()}
          speech="l5Badges"
          iconStyle={{ left: '-1%', top: '29%', width: '26%', height: '42%' }}
          imageSrc={badges}
          canvas={true}
          hints={badgesHints}
          name="badges"
          description="Police badges? These don’t look legit…"
        />
      )}
    </div>
  );
};
