import React from 'react';
import classnames from 'classnames';
import { GameItem, sounds, speech } from '../../../components';
import { useCurrentPlayer } from '../../../state';
import { useAnimalDevice } from './soundDeviceState';

import style from './AnimalDevice.module.css';

import device1 from './img/animalDevice1.png';
import device2 from './img/animalDevice2.png';

import buttonPlay from './img/playPressed.png';
import buttonSealions from './img/sealPressed.png';
import buttonSeagull from './img/seagullPressed.png';
import buttonWhale from './img/whalePressed.png';
import buttonDolphin from './img/dolphinPressed.png';

import light from './img/light.png';

export const animalDeviceImages = [
  device1,
  device2,
  buttonPlay,
  buttonSealions,
  buttonSeagull,
  buttonWhale,
  buttonDolphin,
  light
];

const hints = [
  'This puzzle uses this audio player and the same item from the other team.',
  'When you press the play button you will hear a series of noises which should be represented by the buttons you have available, working together will help.',
  'Pressing the correct buttons in the sequence heard from the device should allow you to complete the puzzle, listen closely to the message.',
  'The killer wants you to use his number of victims as the number for this puzzle but make sure you get them all!',
  'Count victims from the newspapers. This should total 5.'
];

const Lights = ({ num }: { num: number }) => {
  const lights = [];
  for (let i = 0; i < 6; i++) {
    lights.push(
      <div
        className={classnames({ [style.light]: true, [style.on]: i < num })}
        style={{ backgroundImage: `url(${light})` }}
      />
    );
  }
  return <div className={style.lights}>{lights}</div>;
};

export const AnimalDevice = () => {
  const [{ currentPlayer: { team } }] = useCurrentPlayer();
  const isBlueTeam = team === 'blue';
  const [{ input, success }, addSoundInput] = useAnimalDevice();

  function play() {
    sounds.animals();
  }

  function playSuccess() {
    speech.say('ckLevel4AudioDevice', { force: true });
  }

  function playError() {
    sounds.error();
  }

  return (
    <GameItem
      iconStyle={{ left: '-2%', top: '66%', width: '44%', height: '42%', transform: 'rotate(-19deg)' }}
      imageSrc={isBlueTeam ? device1 : device2}
      canvas={false}
      name="l4AnimalDevice"
      description={isBlueTeam ? 'Ahh, who doesn’t love the sounds of the sea!' : 'This broken toy wouldn’t get a seal of approval!'}
      hints={hints}
      speech={isBlueTeam ? 'stefanLevel4soundDevice' : 'allyLevel4soundDevice'}
    >
      <Lights num={success ? 6 : input.length} />
      <button
        type="button"
        className={classnames(style.button, style.buttonPlay)}
        style={{ backgroundImage: `url(${buttonPlay})` }}
        onClick={() => success ? playSuccess() : play()}
      >{' '}
      </button>
      <button
        type="button"
        className={classnames(style.button, style.buttonLeft, isBlueTeam ? style.buttonDolphin : style.buttonSealions)}
        style={{ backgroundImage: `url(${isBlueTeam ? buttonDolphin : buttonSealions})` }}
        onClick={() => success ? playError() : addSoundInput(isBlueTeam ? 'dolphin' : 'sealions')}
      >{' '}
      </button>
      <button
        type="button"
        className={classnames(style.button, style.buttonRight, isBlueTeam ? style.buttonSeagull : style.buttonWhale)}
        style={{ backgroundImage: `url(${isBlueTeam ? buttonSeagull : buttonWhale})` }}
        onClick={() => success ? playError() : addSoundInput(isBlueTeam ? 'seagulls' : 'whale')}
      >{' '}
      </button>

    </GameItem>
  );
};
