import React from 'react';
import ReactPlayer from 'react-player';

import { GameItem } from '../../../components';

import style from './Player.module.css';

import playerImg from './img/player.png';

export const playerImages = [
  playerImg
];

const hints = [
  'This video player is the only part to this puzzle.',
  'Stopping the video at the correct moment may reveal the answer you need.',
  'if you can catch it there is a number 3 that shows up during the video which is the answer you need.'
];

export const Player = () => (
  <GameItem
    iconStyle={{ left: '60%', top: '75%', width: '35.3%', height: '30%', transform: 'rotate(-7.9deg' }}
    canvas={false}
    name="l4Player"
    description="Is that him? Stop! In the name of the law!"
    imageSrc={playerImg}
    hints={hints}
    speech="allyLevel4videoPlayer"
  >
    <ReactPlayer
      className={style.videoPlayer}
      url="https://vimeo.com/490123312"
      controls={true}
      muted={true}
      width="500px"
      height="320px"
      loop={true}
      playing={true}
    />
  </GameItem>
);
