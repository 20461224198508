import React from 'react';
import classnames from 'classnames';

import { DeviceModal, deviceImages } from './DeviceModal';
import { GameLock, sounds, Solution, GameItem, speech } from '../../components';
import { useCurrentPlayer } from '../../state';

import style from './IntroLevel.module.css';

import location from './img/location.jpg';
import lock from './img/introLock.png';
import chestOpens from './img/chestOpens.png';
import newspaper1 from './img/newspaper1.png';
import phone from './img/phone.png';

export const introImages = [
  '/img/l1.jpg',
  location,
  lock,
  chestOpens,
  newspaper1,
  phone,
  ...deviceImages
];

const solutions: Solution[] = [
  { combination: '4731', correct: true }
];

const lockHints = [
  'This lock requires information from the strange digital device.',
  'Using the information gathered from the strange device, you should use the pattern from around the lock to get the code',
  'The code will be the numbers associated with triangle, diamond, square, circle in that order',
  'The answer is 4731.'
];

interface Level1WaitingProps {
  step: number;
}

export const Level1Waiting = ({ step }: Level1WaitingProps) => (
  <>
    <div className={classnames(style.stepsContainer, style[`stepsActive${step}`])}>
      <div className={style.step1}>
        <h1>The detectives are in danger!</h1>
        <p>After getting an anonymous tip to check out a warehouse in the shipping areas of their respective towns, two former partners arrive at their scenes to find a chest had been left for them.</p>
        <p><img src={chestOpens} alt="chest" style={{ width: '200px' }} /></p>
        <p>Upon seeing the chest, they realise that the “one that got away” from them, the Cryptic Killer, is back and they are his next targets!</p>
      </div>
      <div className={style.step2}>
        <h1>The detectives call you for help</h1>
        <p>The detectives reconnect and soon realise that they may not have what it takes to come out of the other side of this without some help.</p>
        <p>
          <img src={phone} alt="Phone" />
        </p>
        <p>Knowing they are in grave danger they have called on you for your expertise in puzzle solving and code-breaking.</p>
      </div>
      <div className={style.step3}>
        <h1>The detectives are counting on you!</h1>
        <p>You are tasked with helping them to solve the devious puzzles that the killer has set out, given a view on what the detectives are seeing you will need to work together with the other team to help them through.</p>
        <p><img src={newspaper1} alt="Newspaper" style={{ width: '480px' }} /></p>
        <p>Can you pull off what his previous victims haven’t managed to do and allow them to escape the clutches of the killer?</p>
      </div>
    </div>
  </>
);

export const Level2Waiting = () => (
  <>
    <h1>Congratulations! You have managed to complete the first level!</h1>
    <p>The chest opens with a loud creak.</p>
    <p><img src={chestOpens} alt="chestopens" style={{ width: '150px' }} /></p>
    <p>Inside, the detective reveals several items and another chest similar to the one they just opened. It’s locked with a chain and padlock. Guess we need to figure out how to open it…</p>
    <p>As the detective starts rummaging through the contents, something stops them, and they decide it’s best to wait for the other team before having a closer look.</p>
  </>
);

export const IntroLevel = () => {
  const [{ currentPlayer: { team } }, { changeLevel }] = useCurrentPlayer();
  const isBlueTeam = team === 'blue';

  return (
    <div className={style.page}>
      <GameLock
        type="numbers"
        iconStyle={{ left: '29%', top: '47%', width: '12%', height: '21%' }}
        numberContainerStyle={{ top: '237px' }}
        imageSrc={lock}
        name="l1Lock"
        description="A numerical lock, looks like it needs four numbers to open it."
        solutions={solutions}
        hints={lockHints}
        onSolved={() => {
          setTimeout(() => sounds.success2(), 5);
          setTimeout(() => changeLevel('2-waiting'), 1500);
        }}
        speech={isBlueTeam ? 'stefanLevel1Chest' : 'allyLevel1Chest'}
      />
      <DeviceModal />
      <GameItem
        iconStyle={{ height: '23%', width: '14%', left: '54%', top: '30%' }}
        disabled={true}
        onClick={() => speech.say('ckLevel1Ahoy', { force: true })}
        name="speaker"
        description="Speaker"
      />
    </div>
  );
};
