import { useEffect } from 'react';
import { useCurrentPlayer, useAccessCode } from '.';

const urlName = (new URL(window.location.href)).searchParams.get('name');
const urlTeam = (new URL(window.location.href)).searchParams.get('team');
const urlLevel = (new URL(window.location.href)).searchParams.get('level');
const urlCode = (new URL(window.location.href)).searchParams.get('code');

type DebugModeResult = [{
  debugMode: boolean;
}];

export function useDebugMode(): DebugModeResult {
  const [, { setCurrentPlayer }] = useCurrentPlayer();
  const [, { setCode }] = useAccessCode();
  const debugMode = !!(urlName && urlCode && urlTeam && urlLevel);

  useEffect(() => {
    if (debugMode) {
      setCode(`${urlCode}`);
      setCurrentPlayer({
        name: `${urlName}`,
        team: `${urlTeam}`,
        level: `${urlLevel}`
      });
    }
    // eslint-disable-next-line
  }, []);

  return [{ debugMode }];
}
