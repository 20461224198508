import React, { useCallback } from 'react';
import classnames from 'classnames';
import { Modal } from 'antd';
import { sounds, speech } from '../../../components';
import { useBombLevel } from '../state';
import { useCurrentPlayer, useGame } from '../../../state';

import style from './Wires.module.css';

import r0 from './img/r0.png';
import r1 from './img/r1.png';
import r2 from './img/r2.png';
import r3 from './img/r3.png';
import r4 from './img/r4.png';
import w0 from './img/w0.png';
import w1 from './img/w1.png';
import w2 from './img/w2.png';
import w3 from './img/w3.png';
import w4 from './img/w4.png';
import g0 from './img/g0.png';
import g1 from './img/g1.png';
import g2 from './img/g2.png';
import g3 from './img/g3.png';
import g4 from './img/g4.png';
import b0 from './img/b0.png';
import b1 from './img/b1.png';
import b2 from './img/b2.png';
import b3 from './img/b3.png';
import b4 from './img/b4.png';

import change from './img/reload.png';
import cutRed from './img/cutRed.png';
import cutBlue from './img/cutBlue.png';
import cutGreen from './img/cutGreen.png';
import cutWhite from './img/cutWhite.png';

const r = [r0, r1, r2, r3, r4];
const w = [w0, w1, w2, w3, w4];
const g = [g0, g1, g2, g3, g4];
const b = [b0, b1, b2, b3, b4];

export const Wires = () => {
  const [wires, { setWires }] = useBombLevel();
  const [{ data }, { addPenalty }] = useGame();
  const [, { changeLevel }] = useCurrentPlayer();
  const bombArmed = data?.bombArmed || false;

  function incrementWire(wire: 'w'|'r'|'g'|'b') {
    const newWires: any = { ...wires };
    newWires[wire] = (wires[wire] + 1) % 5;
    setWires(newWires);
    sounds.connect();
  }

  const cutWire = useCallback((wire: string) => {
    Modal.confirm({
      icon: null,
      title: 'Are you sure?',
      content: <>Do you really want to cut <br />the {`${wire.toUpperCase()}`} wire?</>,
      okText: 'CUT, I\'m sure!',
      cancelText: 'Let me think about it...',
      style: {
        top: '200px'
      },
      centered: false,
      maskClosable: true,
      className: classnames(style.confirmModal, 'game-confirm'),
      onOk: () => {
        if (wire === 'red') {
          sounds.fanfare();
          setTimeout(() => {
            changeLevel('6');
          }, 1000);
        } else {
          speech.say('ckWrongWire', { force: true });
          setTimeout(() => {
            addPenalty();
            sounds.error();
          }, 5000);
        }
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={classnames(style.hole, style.holeLeft1)} />
      <div className={classnames(style.hole, style.holeLeft2)} />
      <div className={classnames(style.hole, style.holeLeft3)} />
      <div className={classnames(style.hole, style.holeLeft4)} />
      <div className={classnames(style.hole, style.holeRight1)} />
      <div className={classnames(style.hole, style.holeRight2)} />
      <div className={classnames(style.hole, style.holeRight3)} />
      <div className={classnames(style.hole, style.holeRight4)} />
      {bombArmed ? (
        <>
          <img src={cutGreen} alt="cut1" onClick={() => cutWire('green')} className={classnames(style.cut, style.cut1)} />
          <img src={cutWhite} alt="cut2" onClick={() => cutWire('white')} className={classnames(style.cut, style.cut2)} />
          <img src={cutRed} alt="cut3" onClick={() => cutWire('red')} className={classnames(style.cut, style.cut3)} />
          <img src={cutBlue} alt="cut4" onClick={() => cutWire('blue')} className={classnames(style.cut, style.cut4)} />
        </>
      ) : (
        <>
          <img src={change} alt="change1" onClick={() => incrementWire('r')} className={classnames(style.change, style.change1)} />
          <img src={change} alt="change2" onClick={() => incrementWire('w')} className={classnames(style.change, style.change2)} />
          <img src={change} alt="change3" onClick={() => incrementWire('g')} className={classnames(style.change, style.change3)} />
          <img src={change} alt="change4" onClick={() => incrementWire('b')} className={classnames(style.change, style.change4)} />
        </>
      )}
      <img src={b[wires.b]} alt="blue" className={classnames(style.wire)} />
      <img src={g[wires.g]} alt="green" className={classnames(style.wire)} />
      <img src={w[wires.w]} alt="white" className={classnames(style.wire)} />
      <img src={r[wires.r]} alt="red" className={classnames(style.wire)} />
    </>
  );
};
