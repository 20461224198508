import React from 'react';
import { useDrop } from 'react-dnd';

import style from './Beakers.module.css';

import beakerMix from './img/beakerMix.png';

export const beakerDropzoneImages = [beakerMix];

interface BeakerDropzoneProps {
  beakerColor: string;
}

export const BeakerDropzone: React.FC<BeakerDropzoneProps> = ({ beakerColor }) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'beaker',
    drop: () => ({ name: 'BeakerDropzone' }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const isActive = canDrop && isOver;
  let borderColor = '#ccc';
  if (isActive) {
    borderColor = 'green';
  } else if (canDrop) {
    borderColor = 'pink';
  }

  return (
    <div ref={drop} className={style.beakerDrop} style={{ borderColor }}>
      <p>{isActive ? 'Release to drop' : 'Drag a beaker here to mix'}</p>
      <img src={beakerMix} alt="Beaker" className={style.beakerMix} />
      <div className={style.color} style={{ backgroundColor: beakerColor }} />
    </div>
  );
};
