import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useStoreState } from 'easy-peasy';
import { Modal } from 'antd';

import { useGame, Game } from './game';
import { sounds, speech } from '../components';
import { useAccessCode } from '.';

/**
 * @param {number} time in seconds * 1000
 */
export function formatTimer(time: number) {
  const timer = Math.max(Math.floor(time / 1000), 0);

  // timer is in seconds
  const s = timer % 60;
  const m = ((timer - s) / 60) % 60;
  const h = (timer - s - m * 60) / 3600;

  return `${h}:${m < 10 ? '0' : ''}${m}:${s < 10 ? '0' : ''}${s}`;
}

type TimerMode = '90min' | '30min' | '30min2' | 'bomb' | 'bomb2' | 'normal' | 'none';

type Timer = {
  mode: TimerMode;
  timer: number;
  timeElapsed: number;
  paused: boolean;
}

type TimerResponse = [Timer];

const TIMER_DEFAULT: Timer = {
  mode: 'none',
  timer: 0,
  timeElapsed: 0,
  paused: true
};

const MINUTES_90 = 90 * 60 * 1000;
const MINUTES_60 = 60 * 60 * 1000;
const MINUTES_30 = 30 * 60 * 1000;
const MINUTES_5 = 5 * 60 * 1000;

const calculateTimeElapsed = (data: Game): { elapsed: number; paused: boolean } => {
  let elapsed = 0;
  let paused = true;

  if (!data || !data.level1Start) {
    return {
      elapsed: MINUTES_90,
      paused: true
    };
  }

  if (data.level1Start) {
    elapsed += data.level1End ? data.level1End - data.level1Start : (+moment() - data.level1Start);
    paused = !!data.level1End;
  }
  if (data.level2Start) {
    elapsed += data.level2End ? data.level2End - data.level2Start : (+moment() - data.level2Start);
    paused = !!data.level2End;
  }
  if (data.level3Start) {
    elapsed += data.level3End ? data.level3End - data.level3Start : (+moment() - data.level3Start);
    paused = !!data.level3End;
  }
  if (data.level4Start) {
    elapsed += data.level4End ? data.level4End - data.level4Start : (+moment() - data.level4Start);
    paused = !!data.level4End;
  }
  if (data.level5Start) {
    elapsed += data.level5End ? data.level5End - data.level5Start : (+moment() - data.level5Start);
    paused = !!data.level5End;
  }

  if (data.penalties) {
    elapsed += data.penalties * 60 * 1000; // add a minute per penalty
  }

  return { elapsed, paused };
};

// returns timer in seconds
export function useTimer(shouldTick?: boolean): TimerResponse {
  const [, { isCorp }] = useAccessCode();
  const [{ data }] = useGame();
  const openModal = useStoreState(state => state.app.modalOpen);
  const [prevMode, setPrevMode] = useState(''); // important for the state machine stuff (to show the modal only on real state changes)
  const start = data?.level1Start || 0;
  const bombStart = data?.bombStartedAt || 0;
  const [timer, setTimerState] = useState<Timer>(TIMER_DEFAULT);
  const isBombModalOpen = openModal === 'bomb';
  const defaultGameLength = isCorp() ? MINUTES_60 : MINUTES_90; // corp games have one level less and are shorter

  useEffect(() => {
    const interval = setInterval(() => {
      // const timeElapsed = +moment() - start; // seconds * 1000 from the start
      const { elapsed: timeElapsed, paused } = calculateTimeElapsed(data as Game); // seconds * 1000 from the start
      const timeElapsedBomb = +moment() - bombStart + (data?.penalties || 0) * 60 * 1000; // seconds * 1000 from the bomb start

      if (start === 0) {
        // not started yet
        setTimerState(TIMER_DEFAULT);
      } else if (bombStart === 0 && timeElapsed <= defaultGameLength) {
        setTimerState({ mode: '90min', timer: defaultGameLength - timeElapsed, timeElapsed, paused });
        setPrevMode('90min');
      } else if (bombStart === 0 && timeElapsed > defaultGameLength && timeElapsed < defaultGameLength + MINUTES_30) {
        setTimerState({ mode: '30min', timer: defaultGameLength + MINUTES_30 - timeElapsed, timeElapsed, paused });
        if (prevMode === '90min' && shouldTick) {
          Modal.warning({
            icon: null,
            content: (
              <>
                <p>The Cryptic Killer seems to be going easy on you, it’s almost as though he wants you to beat his puzzles...</p>
                <p style={{ marginBottom: 0 }}>Is this a trap?!</p>
              </>
            ),
            style: { textAlign: 'center' },
            centered: true
          });
          speech.say('ckMainTimer', { force: true });
        }
        setPrevMode('30min');
      } else if (bombStart === 0 && timeElapsed > defaultGameLength + MINUTES_30 && timeElapsed < defaultGameLength + MINUTES_30 * 2) {
        setTimerState({ mode: '30min2', timer: defaultGameLength + MINUTES_30 * 2 - timeElapsed, timeElapsed, paused });
        setPrevMode('30min2');
        if (prevMode === '30min' && shouldTick) {
          Modal.warning({
            icon: null,
            content: (
              <>
                <p>The Cryptic Killer seems to be going easy on you, it’s almost as though he wants you to beat his puzzles...</p>
                <p style={{ marginBottom: 0 }}>Is this a trap?!</p>
              </>
            ),
            style: { textAlign: 'center' },
            centered: true
          });
          speech.say('ckMainTimer', { force: true });
        }
      } else if (bombStart && timeElapsedBomb <= MINUTES_5) {
        setTimerState({ mode: 'bomb', timer: MINUTES_5 - timeElapsedBomb, timeElapsed, paused });
        setPrevMode('bomb');
        if (shouldTick) {
          sounds.tick(isBombModalOpen ? 1 : 0.2);
        }
      } else if (bombStart && timeElapsedBomb <= MINUTES_5 * 2) {
        setTimerState({ mode: 'bomb2', timer: MINUTES_5 * 2 - timeElapsedBomb, timeElapsed, paused });
        if (shouldTick) {
          sounds.tick(isBombModalOpen ? 1 : 0.2);
        }
        if (prevMode === 'bomb' && shouldTick) {
          Modal.warning({
            icon: null,
            content: (
              <>
                <p>It seems like things haven't gone to plan for the Cryptic Killer, perhaps you can defuse the bomb before he can remote detonate it!</p>
                <p style={{ marginBottom: 0 }}>Sounds like you have 5 more minutes! Good luck!!</p>
              </>
            ),
            style: { textAlign: 'center' },
            centered: true
          });
          speech.say('ckBombFirstTimer', { force: true });
        }
        setPrevMode('bomb2');
      } else {
        setTimerState({ mode: 'normal', timer: timeElapsed, timeElapsed, paused });
        if (prevMode === 'bomb2' && shouldTick) {
          Modal.warning({
            icon: null,
            content: (
              <>
                <p>Luckily for you it seems like the Cryptic Killer is having a bad day! The timer has stopped counting down but your detective can't escape the room without the bomb going off.</p>
                <p style={{ marginBottom: 0 }}>Cut the wire to save the detectives (if you're really stuck there are clues available).</p>
              </>
            ),
            style: { textAlign: 'center' },
            centered: true
          });
          speech.say('ckBombSecondTimer', { force: true });
        } else if (prevMode === '30min2' && shouldTick) {
          Modal.warning({
            icon: null,
            content: (
              <>
                <p>Luckily for you it seems like the Cryptic Killer is having a bad day! The timer has stopped counting down but your detective can't escape the room.</p>
                <p style={{ marginBottom: 0 }}>Save the detectives (if you're really stuck there are clues available)!</p>
              </>
            ),
            style: { textAlign: 'center' },
            centered: true
          });
          speech.say('ckMainTimer', { force: true });
        }
        setPrevMode('normal');
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [start, bombStart, shouldTick, isBombModalOpen, prevMode, data, defaultGameLength]);

  return [timer];
}
