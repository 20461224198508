import React, { useState, useEffect, useCallback } from 'react';
import { Button, Progress } from 'antd';
import classnames from 'classnames';

import { preloadImages } from '../utils/preloadImages';
import { useCurrentPlayer } from '../state';
import { preloadAudioFiles, speech, speechBubbleImages, controlsImages, sounds, MagicFrame } from '../components';
import {
  Level1Waiting,
  introImages,
  Level2Waiting,
  level2Images,
  Level3Waiting,
  level3Images,
  Level4Waiting,
  level4Images,
  Level5Waiting,
  bombLevelImages
} from '../levels';

import style from './WaitingLevel.module.css';

interface WaitingLevelProps {
  nextLevel: string;
}

const componentImages = [...speechBubbleImages, ...controlsImages];

interface StepperProps {
  step: number;
  setStep: (step: number) => void;
}

const Stepper = ({ step, setStep }: StepperProps) => (
  <div className={style.stepper}>
    <div
      className={classnames(style.step1, step === 1 && style.stepActive)}
      onClick={() => setStep(1)}
    />
    <div
      className={classnames(style.step2, step === 2 && style.stepActive)}
      onClick={() => setStep(2)}
    />
    <div
      className={classnames(style.step3, step === 3 && style.stepActive)}
      onClick={() => setStep(3)}
    />
  </div>
);

export const WaitingLevel = ({ nextLevel }: WaitingLevelProps) => {
  const [step, setStep] = useState(1);
  const [, { changeLevel }] = useCurrentPlayer();
  const [{ imagesProgress, imagesCompleted }, setImagesProgress] = useState({
    imagesProgress: 0,
    imagesCompleted: false
  });
  const [{ soundsProgress, soundsCompleted }, setSoundsProgress] = useState({
    soundsProgress: 0,
    soundsCompleted: false
  });

  const shouldPreload = ['1', '2', '3', '4', '5'].indexOf(nextLevel) !== -1;
  const completed = imagesCompleted && soundsCompleted;
  const progress = (imagesProgress + soundsProgress) / 2;

  const doContinue = useCallback(() => {
    if (nextLevel === '1' && [1, 2].indexOf(step) !== -1) {
      setStep(step + 1);
    } else {
      changeLevel(nextLevel);
      sounds.mystery();
      setTimeout(() => window.scrollTo(0, 0), 1);
    }
  }, [changeLevel, nextLevel, step, setStep]);

  useEffect(() => {
    let imagesToPreload: string[] = [];

    if (nextLevel === '1') {
      imagesToPreload = introImages;
    } else if (nextLevel === '2') {
      speech.say('ckLevel1Complete', { darkOverlay: true });
      imagesToPreload = level2Images;
    } else if (nextLevel === '3') {
      speech.say('ckLevel2Complete', { darkOverlay: true });
      imagesToPreload = level3Images;
    } else if (nextLevel === '4') {
      speech.say('ckLevel3Complete', { darkOverlay: true });
      imagesToPreload = level4Images;
    } else if (nextLevel === '5') {
      speech.say('ckLevel4Complete', { darkOverlay: true });
      imagesToPreload = bombLevelImages;
    }

    imagesToPreload.push(...componentImages);

    preloadImages(imagesToPreload, (progr, done) => {
      setImagesProgress({
        imagesProgress: progr,
        imagesCompleted: done
      });
    });

    preloadAudioFiles((progr, done) => {
      setSoundsProgress({
        soundsProgress: progr,
        soundsCompleted: done
      });
    });

    // eslint-disable-next-line
  }, [nextLevel]);

  function renderWaiting() {
    switch (nextLevel) {
      case '1':
        return <Level1Waiting step={step} />;
      case '2':
        return <Level2Waiting />;
      case '3':
        return <Level3Waiting />;
      case '4':
        return <Level4Waiting />;
      case '5':
        return <Level5Waiting />;
      default:
        return <>Oops!</>;
    }
  }

  function renderButtonText() {
    if (nextLevel === '1' && [1, 2].indexOf(step) !== -1) {
      return 'Continue';
    }
    if (shouldPreload && !completed) {
      return 'Loading level...';
    }
    if (nextLevel === '1') {
      return 'Start the game!';
    }
    return 'Continue to the next level';
  }

  return (
    <MagicFrame className={style.container}>
      <div className={style.center}>
        <div className={style.story}>
          {renderWaiting()}
        </div>

        {nextLevel === '1' && <Stepper step={step} setStep={setStep} />}

        <p>
          <Button
            className={style.button}
            type="primary"
            size="large"
            data-cy="continueButton"
            disabled={(shouldPreload && !completed && nextLevel !== '1') || (nextLevel === '1' && [1, 2].indexOf(step) === -1 && !completed)}
            onClick={() => doContinue()}
          >
            {renderButtonText()}
          </Button>
        </p>
        {shouldPreload && (
          <Progress
            percent={Math.round(progress * 100)}
            status={completed ? 'success' : 'active'}
            showInfo={false}
            strokeColor="#66101F"
            trailColor="#DEC7CC"
          />
        )}
      </div>
    </MagicFrame>
  );
};
