export const voices = {
  allyLevel0Picking: {
    file: './sounds/Ally/Ally Level 0 Picking Ally.mp3',
    text: 'Aww, your gonna help me? Great stuff!',
    delay: 5000,
    person: 'ally'
  },
  allyLevel1Chest: {
    file: './sounds/Ally/Ally Level 1 Chest.mp3',
    text: 'Aww naw, we’ve seen this type of box before.',
    delay: 5000,
    person: 'ally'
  },
  allyLevel1device: {
    file: './sounds/Ally/Ally Level 1 device.mp3',
    text: 'Okay, here we go, I’m sure you’ll be able tae handle this.',
    delay: 5000,
    person: 'ally'
  },
  allyLevel2Cryptex: {
    file: './sounds/Ally/Ally Level 2 Cryptex.mp3',
    text: 'Wow, I thought they stopped using these centuries ago.',
    delay: 5000,
    person: 'ally'
  },
  allyLevel2NewFishingPuzzle: {
    file: './sounds/Ally/Ally Level 2 New fishing puzzle.mp3',
    text: 'I’ve seen loads of these, my Da used to be a fisherman.',
    delay: 5000,
    person: 'ally'
  },
  allyLevel2Padlock: {
    file: './sounds/Ally/Ally Level 2 Padlock.mp3',
    text: 'Great, that seems pretty self-explanatory.',
    delay: 5000,
    person: 'ally'
  },
  allyLevel2PaperWithNumbers: {
    file: './sounds/Ally/Ally Level 2 Paper with numbers.mp3',
    text: 'That’s an awfy lot of numbers! Whit da ye think they mean?',
    delay: 5000,
    person: 'ally'
  },
  allyLevel2Switches: {
    file: './sounds/Ally/Ally Level 2 Switches.mp3',
    text: 'Och, that looks pretty simple from here.',
    delay: 5000,
    person: 'ally'
  },
  allyLevel2TorFstatements: {
    file: './sounds/Ally/Ally Level 2 T or F statements.mp3',
    text: 'Easy, you’ve got this!',
    delay: 5000,
    person: 'ally'
  },
  allyLevel3ElectronicPuzzle: {
    file: './sounds/Ally/Ally Level 3 Electronic puzzle.mp3',
    text: 'Ok, looks like I can only press up or doon here.',
    delay: 5000,
    person: 'ally'
  },
  allyLevel3Newspaper: {
    file: './sounds/Ally/Ally Level 3 Newspaper.mp3',
    text: 'Aww, these red topped papers are a load of rubbish.',
    delay: 5000,
    person: 'ally'
  },
  allyLevel3PieceOfPaper: {
    file: './sounds/Ally/Ally Level 3 Piece of paper.mp3',
    text: 'Whits that even mean?',
    delay: 5000,
    person: 'ally'
  },
  allyLevel3Watches: {
    file: './sounds/Ally/Ally Level 3 Watches.mp3',
    text: 'Strange, letters dinae belong on a watch?',
    delay: 5000,
    person: 'ally'
  },
  allyLevel3WoodCarving: {
    file: './sounds/Ally/Ally Level 3 Wood carving.mp3',
    text: 'Makes nae sense tae me!',
    delay: 5000,
    person: 'ally'
  },
  allyLevel3padlock: {
    file: './sounds/Ally/Ally Level 3 padlock.mp3',
    text: 'Oh nae, looks more complicated than the last one.',
    delay: 5000,
    person: 'ally'
  },
  allyLevel4LockAfterPower: {
    file: './sounds/Ally/Ally Level 4 Lock after power.mp3',
    text: 'Just huv tae find the combination now!',
    delay: 5000,
    person: 'ally'
  },
  allyLevel4LockBeforePower: {
    file: './sounds/Ally/Ally Level 4 Lock before power.mp3',
    text: 'Oh naw it\'s deed, it must need power.',
    delay: 5000,
    person: 'ally'
  },
  allyLevel4PeriodicTable: {
    file: './sounds/Ally/Ally Level 4 Periodic table.mp3',
    text: 'Takes me back ta being a wain in chemistry class.',
    delay: 5000,
    person: 'ally'
  },
  allyLevel4PlaneTickets: {
    file: './sounds/Ally/Ally Level 4 Plane tickets.mp3',
    text: 'This killer\'s fair flying all o’er the place.',
    delay: 5000,
    person: 'ally'
  },
  allyLevel4flasks: {
    file: './sounds/Ally/Ally Level 4 flasks.mp3',
    text: 'This is thursty work, but ye best no drink these…',
    delay: 5000,
    person: 'ally'
  },
  allyLevel4newspaper: {
    file: './sounds/Ally/Ally Level 4 newspaper.mp3',
    text: 'I’m sure that’s Auld dogs stomping ground!',
    delay: 5000,
    person: 'ally'
  },
  allyLevel4powerBox: {
    file: './sounds/Ally/Ally Level 4 power box.mp3',
    text: 'I\'ve nae clue, chemistry\'s no ma strong point.',
    delay: 5000,
    person: 'ally'
  },
  allyLevel4soundDevice: {
    file: './sounds/Ally/Ally Level 4 sound device.mp3',
    text: 'Whit a racket that makes!',
    delay: 5000,
    person: 'ally'
  },
  allyLevel4videoPlayer: {
    file: './sounds/Ally/Ally Level 4 video player.mp3',
    text: 'Amazing whit they can da in a wee thing like this!',
    delay: 5000,
    person: 'ally'
  },
  allyLevel5BombChange1: {
    file: './sounds/Ally/Ally Level 5 Bomb change 1.mp3',
    text: 'Oh no! Right, everybody... We can do this!',
    delay: 5000,
    person: 'ally'
  },
  allyLevel5BombChange2: {
    file: './sounds/Ally/Ally Level 5 Bomb change 2.mp3',
    text: 'Oh no, no! What is this?! What\'s happening?',
    delay: 4000,
    person: 'ally'
  },
  allyLevel5BombChange3: {
    file: './sounds/Ally/Ally Level 5 Bomb change 3.mp3',
    text: 'Oh no, what is this, what\'s happening? Ok, we can do this!',
    delay: 5000,
    person: 'ally'
  },
  allyLevel5Bomb: {
    file: './sounds/Ally/Ally Level 5 Bomb.mp3',
    text: 'Oh no, okay folks, nae pressure but I really want tae get home to ma family.',
    delay: 7000,
    person: 'ally'
  },
  allyLevel5JigaswPuzzle: {
    file: './sounds/Ally/Ally Level 5 Jigasw puzzle.mp3',
    text: 'A jigsaw puzzle? No so much fun under pressure though.',
    delay: 5000,
    person: 'ally'
  },
  allyLevel5NewPuzzle: {
    file: './sounds/Ally/Ally Level 5 New puzzle.mp3',
    text: 'Whits this strange lookin\' thing?',
    delay: 5000,
    person: 'ally'
  },
  allyLevel5PaintSlodges: {
    file: './sounds/Ally/Ally Level 5 Paint slodges.mp3',
    text: 'Aww reminds me of ma table after the kids have been paintin.',
    delay: 5000,
    person: 'ally'
  },
  allyLevel5FishSupper: {
    file: './sounds/Ally/Ally Level 5 Fish supper.mp3',
    text: 'Aw a could pure go a fish supper right noo.',
    delay: 5000,
    person: 'ally'
  },
  allyLevel5SlidingPuzzle: {
    file: './sounds/Ally/Ally Level 5 Sliding puzzle.mp3',
    text: 'Ma heeds mince, I’m bad at these puzzles at the best of times.',
    delay: 5000,
    person: 'ally'
  },
  allyLevel5WindPuzzleWindDirections: {
    file: './sounds/Ally/Ally Level 5 Wind puzzle wind directions.mp3',
    text: 'I’m stumped? You’ve got this though.',
    delay: 5000,
    person: 'ally'
  },
  allyLevel5windPuzzleSemaphore: {
    file: './sounds/Ally/Ally Level 5 wind puzzle semaphore.mp3',
    text: 'Compass and semaphores? Yikes, I’m not a fan of the sea!',
    delay: 5000,
    person: 'ally'
  },
  allyLevel5MusicPiece: {
    file: './sounds/Ally/Ally level 5 Music Piece.mp3',
    text: 'That’s an awfy short piece of music.',
    delay: 5000,
    person: 'ally'
  },

  stefanLevel0Picking: {
    file: './sounds/OldDog/HeyKidSureHelpMe.mp3',
    text: 'Hey kid, sure, help me if you want!',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel0SoundCheck: {
    file: './sounds/OldDog/HeyKidIHopeYouCanHearMe.mp3',
    text: 'Hey kid, I hope you can hear me.',
    delay: 4000,
    person: 'stefan'
  },
  stefanLevel1Chest: {
    file: './sounds/OldDog/WowThatsAHugeChest.mp3',
    text: 'Wow, that’s a huge chest!',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel1device: {
    file: './sounds/OldDog/RightGeniousYoureUp.mp3',
    text: 'Right genius you’re up, let\'s see what you’re made of.',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel2Photo: {
    file: './sounds/OldDog/WhatKindOfNameIsThat.mp3',
    text: 'What kind of name is that?',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel2NewFishingPuzzle: {
    file: './sounds/OldDog/BetterShareThisInfo2.mp3',
    text: 'Better share this information with the other team kid.',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel2Padlock: {
    file: './sounds/OldDog/SeemsPrettySimpleYoullManage.mp3',
    text: 'Seems pretty simple, I’m sure you will manage.',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel2DottedGrid: {
    file: './sounds/OldDog/ImSureAGeniusLikeYou.mp3',
    text: 'I’m sure a genius like you can connect some dots.',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel2Switches: {
    file: './sounds/OldDog/ImSureEvenYouCanHandleThisOne.mp3',
    text: 'I’m sure even you can handle this one…',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel2TorFstatements: {
    file: './sounds/OldDog/WellYouveGot5050Chance.mp3',
    text: 'Well you’ve got a 50/50 chance.',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel3ElectronicPuzzle: {
    file: './sounds/OldDog/PleaseTellMeYouKnowLeftFromRight.mp3',
    text: 'Please tell me you know left from right?',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel3Newspaper: {
    file: './sounds/OldDog/AProperPuzzleDoYouKnowWhatThisIs.mp3',
    text: 'A proper puzzle, do you even know what this is?',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel3Wheel: {
    file: './sounds/OldDog/LetsHopeYouCanSteerUsOutOfThis.mp3',
    text: 'Let’s hope you can steer us out of this...',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel3Watches: {
    file: './sounds/OldDog/AnalogueClocksHopeYourDigitalBrainCanHandleThis.mp3',
    text: 'Analogue clocks, hope your digital brain can handle this!',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel3TimeWillSet: {
    file: './sounds/OldDog/WellIdonHaveMuchTimeLeftSoGetAMoveOn.mp3',
    text: 'Well, I don’t have much time left so get a move on.',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel3padlock: {
    file: './sounds/OldDog/HopeYoureAsSmartAsYouSayYouAre.mp3',
    text: 'Hope you’re as smart as you say you are, it’s a tough one.',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel4LockAfterPower: {
    file: './sounds/OldDog/CmonThisCouldntBeMoreObviousWhatsTheNumbers.mp3',
    text: 'C’mon this couldn’t be more obvious, what’s the numbers?',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel4LockBeforePower: {
    file: './sounds/OldDog/WeHaventGotAllDayKid.mp3',
    text: 'We haven’t got all day kid best power this up quick.',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel4PeriodicTable: {
    file: './sounds/OldDog/ChemistryUnlessItInvolvesAlcoImOut.mp3',
    text: 'Chemistry, unless it involves alcohol I’m out!',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel4Map: {
    file: './sounds/OldDog/YouBetterAskTheOtherTeamForHelp.mp3',
    text: 'You better ask the other team for help, if you want to work this out.',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel4Flasks: {
    file: './sounds/OldDog/TheOnlyThinkICanMixUpIsAGoodStiffDrink.mp3',
    text: 'The only thing I can mix up is a good stiff drink!',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel4newspaper: {
    file: './sounds/OldDog/PayAttentionToTheDetailsGenius.mp3',
    text: 'Pay attention to the details genius.',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel4powerBox: {
    file: './sounds/OldDog/JustNeedTheRightColorsEvenYouCantMessThisUpIHope.mp3',
    text: 'Just need the right colours. Even you can’t mess this up...I hope.',
    delay: 6000,
    person: 'stefan'
  },
  stefanLevel4soundDevice: {
    file: './sounds/OldDog/YeahRealFunnyWhatAPunk.mp3',
    text: 'Yeah real funny, what a punk!',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel4Torch: {
    file: './sounds/OldDog/IKnowYouKidsAreAllAboutTechButThinkOldSchool.mp3',
    text: 'I know you kids are all about your tech, but think old school here.',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel5BombChange1: {
    file: './sounds/OldDog/OhOhIToldYouNotToMessThisUp.mp3',
    text: 'Oh, oh! I told you to not mess this up!',
    delay: 4000,
    person: 'stefan'
  },
  stefanLevel5Bomb: {
    file: './sounds/OldDog/MomentOfTruthNoPressureJustMyLIfeOnTheLine.mp3',
    text: 'Moment of truth, no pressure, just my life on the line!',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel5JigaswPuzzle: {
    file: './sounds/OldDog/JigsawsAreMyFavoriteBestNotMessThisUp.mp3',
    text: 'Jigsaws are my favourite, best not mess this up!',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel5NewPuzzle: {
    file: './sounds/OldDog/NeverSeenADeviceLikeThisInAllMyYears.mp3',
    text: 'Never seen a device like this in all my years!',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel5PaintedLetters: {
    file: './sounds/OldDog/WhatKindOfQuestionIsThat.mp3',
    text: 'What kind of a question is that?',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel5SlidingPuzzle: {
    file: './sounds/OldDog/TakesMeBackNowThisIsAPuzzle.mp3',
    text: 'Takes me back, now this is a puzzle!',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel5WindPuzzleWindDirections: {
    file: './sounds/OldDog/HandyBestToStandUpwindWhenImAround.mp3',
    text: 'Handy, best to stand upwind when I’m around!',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel5windPuzzleSemaphore: {
    file: './sounds/OldDog/DespiteWhatYouThinkTheseAintCheerleedingInstructions.mp3',
    text: 'Despite what you think these ain’t cheerleading instructions.',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel5MusicPiece: {
    file: './sounds/OldDog/TheKillersMusicChoiceLeavesALotToBeDesired.mp3',
    text: 'The killer’s music choice leaves a lot to be desired.',
    delay: 5000,
    person: 'stefan'
  },
  stefanLevel5Badges: {
    file: './sounds/OldDog/YouDontHaveToBeADetectiveToKnowTheseArentReal.mp3',
    text: 'You don’t have to be a detective to know these aren’t real…',
    delay: 5000,
    person: 'stefan'
  },

  ckLevel1Ahoy: {
    file: './sounds/CK/CK_level_1_Ahoy_mateys.mp3',
    text: 'Ahoy mateys! I\'ve got my eye on you. This is gonna be fun. Ha ha ha!',
    delay: 8000,
    person: 'killer'
  },
  ckLevel1Complete: {
    file: './sounds/CK/Ck Level 1 complete.mp3',
    text: 'Don’t think they\'re gonna be all this easy…',
    delay: 5000,
    person: 'killer'
  },
  ckLevel2Complete: {
    file: './sounds/CK/Ck Level 2 complete.mp3',
    text: 'We’re only just getting started!',
    delay: 5000,
    person: 'killer'
  },
  ckLevel3Complete: {
    file: './sounds/CK/CK Level 3 complete.mp3',
    text: 'Tick tick, tick, better get a move on.',
    delay: 5000,
    person: 'killer'
  },
  ckLevel4AudioDevice: {
    file: './sounds/CK/CK Level 4 Audio device.mp3',
    text: 'The number you seek is the number of my known victims.',
    delay: 5000,
    person: 'killer'
  },
  ckLevel4Complete: {
    file: './sounds/CK/CK level 4 complete.mp3',
    text: 'Think you’re smart, bet you didn’t expect that!',
    delay: 5000,
    person: 'killer'
  },
  ckLevel5BombChange1: {
    file: './sounds/CK/CK Level 5 Bomb change 1.mp3',
    text: 'Thanks for arming my bomb... Tick, tick, tick, BOOM!',
    delay: 5000,
    person: 'killer'
  },
  ckBombFirstTimer: {
    file: './sounds/CK/CK_First_bomb_timer_runs_out.mp3',
    text: 'Why didn’t the bomb explode, I’m going to have to detonate it remotely, you got 5 mins to live!',
    delay: 6000,
    person: 'killer'
  },
  ckBombSecondTimer: {
    file: './sounds/CK/CK_second_bomb_timer_runs_out.mp3',
    text: 'My timer and remote detonation may have failed but you can\'t get out of there alive without that bomb going off',
    delay: 7000,
    person: 'killer'
  },
  ckMainTimer: {
    file: './sounds/CK/CK_main_timer_runs_out.mp3',
    text: 'I thought you were the best there was, I guess I’m going to have to give you some extra time!',
    delay: 7000,
    person: 'killer'
  },
  ckWrongWire: {
    file: './sounds/CK/CK Wrong wire.mp3',
    text: 'Just keep cutting those wires at random. I wanna see you go BOOM!',
    delay: 5000,
    person: 'killer'
  }
};
