import React from 'react';

import { useCurrentPlayer } from '../../state';
import { GameItem, GameLock, sounds } from '../../components';
import { Labyrinth, labyrinthImages } from './Labyrinth';

import style from './Level3.module.css';

import shipWheel from './img/shipWheel.png';
import nonogram from './img/nonogram.png';
import newspaper1 from './img/newspaper1.png';
import newspaper2 from './img/newspaper2.png';
import watches1 from './img/watches1.png';
import watches2 from './img/watches2.png';
import lock from './img/lock.png';
import timeWillSetYouFree1 from './img/timeWillSetYouFree1.png';
import timeWillSetYouFree2 from './img/timeWillSetYouFree2.png';
import latch1OldDog from './img/latch1OldDog.jpg';
import latch2OldDog from './img/latch2OldDog.jpg';
import latch1Ally from './img/latch1Ally.jpg';
import latch2Ally from './img/latch2Ally.jpg';

export const level3Images = [
  '/img/l3Red.jpg',
  '/img/l3Blue.jpg',
  shipWheel,
  nonogram,
  newspaper1,
  newspaper2,
  watches1,
  watches2,
  lock,
  timeWillSetYouFree1,
  timeWillSetYouFree2,
  latch1OldDog,
  latch2OldDog,
  latch1Ally,
  latch2Ally,
  ...labyrinthImages
];

const lockSolutions = [
  { combination: '5834', correct: true },
  { combination: '0111', correct: false }
];

const lockHints = [
  'This lock requires you to find the numbers and position of those numbers from the various puzzles within each teams’ boxes. Working together you should be able to find everything you need and if not then perhaps examine clues on the puzzles or the items in this level.',
  'The answer is 5834.'
];

const carvingHints = [
  'This is a hint towards the puzzle with the watches, it requires the carvings from both teams in order to make sense of it.',
  'Replace the underlined letter from Old Dog’s team\'s carving with the letters from Ally’s team\'s carvings.',
  'The answer will read \'Time will set you three\', this points to the fact that the answer to the time puzzle should be put in the third position of the lock.'
];

const newspaper1Hints = [
  'This is a newspaper which has a puzzle which would be worth completing and also provides information for a puzzle in Old Dog’s team\'s box.',
  'The moon chart here may be of use to someone else…',
  'The nonogram is a puzzle that should be completed using the description above the puzzle. It requires you to shade in boxes that are given by the number that is given at the start of that row/column. A good starting point would be the 7 in the vertical columns. Since there are only 7 boxes, you can fill out all seven boxes in that column and work from there.',
  'If you fill out the boxes correctly, you should see the number 8.'
];

const newspaper2Hints = [
  'This is a newspaper which gives a hint towards the position of one of the numbers from another puzzle',
  'If you complete the word search by finding all the words listed under the grid, what are the letters that are left?',
  'Completing the word search should leave the letters THEWHEELISFIRST – THE WHEEL IS FIRST refers to the position of the puzzle which has the wheel in it, the very same puzzle that you need the moon chart to complete.'
];

const nonogramHints = [
  'This piece of paper contains a clue for the position of one of the numbers from another puzzle.',
  'The symbol on this paper represents a one-gram weight.',
  'The clue is to point you to the number found within the nonograms position, nonogram that must be second.'
];

const shipWheelHints = [
  'The information needed to complete this puzzle comes from this piece of paper and the newspaper in Ally’s team\'s box.',
  'The symbols on the left of the piece of paper are different phases of the moon cycle, their position on the moon cycle can be used to give a letter for each one from the ships wheel.',
  'The letters should spell out the word “knots” and should lead you to count the knots in the rope around the outside of the box. This will give you the number needed for the lock but another puzzle will lead to the position of the answer.',
  'The number of knots in the ropes are 3 in one box and 2 in the other so the answer is 5.'
];

const watchesHints = [
  'The information needed to complete this puzzle is the watches from both teams.',
  'The watches have all stopped at a certain time and the date is an indicator of which order they will come in.',
  'Ally’s team\'s watches contain one which has letters instead of numbers; this is key to solving the problem. Relay the time to Ally’s team for the watch with 01 as the date as the hour hand followed by the minute hand to get letters from their position and repeat for the rest of the watches 2-5.',
  'If completed correctly, the letters you will be given should spell out ONEPLUSTWO, which would mean the answer is three. Another puzzle will give you the position of this number in the lock.'
];

export const Level4Waiting = () => {
  const [{ currentPlayer: { team } }] = useCurrentPlayer();
  const isBlueTeam = team === 'blue';

  const latch1 = isBlueTeam ? latch1OldDog : latch1Ally;
  const latch2 = isBlueTeam ? latch2OldDog : latch2Ally;

  return (
    <>
      <h1>Woo! Good work!</h1>
      <p>The latch has been opened and reveals a handle; it seems ominous!</p>
      <p><img className={style.latchImage} src={latch1} alt="Latch and a handle" /></p>
      <p>The detective hesitates, not sure if they should touch it, but they don't really have a choice! They grab the handle, close their eyes, and lift it up cautiously.</p>
      <p>Slowly opening their eyes, they realise it has revealed a second bottom!</p>
      <p><img className={style.latchImage} src={latch2} alt="Pull the handle" /></p>
    </>
  );
};

export const Level3 = () => {
  const [{ currentPlayer: { team } }, { changeLevel }] = useCurrentPlayer();
  const isBlueTeam = team === 'blue';

  return (
    <div className={style.leevel}>
      <GameLock
        type="numbers"
        iconStyle={{ left: '61.7%', top: '9%', width: '17%', height: '25%', transform: 'rotate(-11deg)' }}
        imageSrc={lock}
        name="l3LeekeysLock"
        description="Looks like we need to figure the position and numbers ourselves this time!"
        numberContainerStyle={{ top: '25%' }}
        solutions={lockSolutions}
        hints={lockHints}
        onSolved={() => {
          sounds.success2();
          setTimeout(() => changeLevel('4-waiting'), 500);
        }}
        speech={isBlueTeam ? 'stefanLevel3padlock' : 'allyLevel3padlock'}
      />
      <GameItem
        iconStyle={{ left: '4%', top: '5%', width: '37%', height: '28%', transform: 'rotate(-8deg)' }}
        imageSrc={isBlueTeam ? newspaper2 : newspaper1}
        hints={isBlueTeam ? newspaper2Hints : newspaper1Hints}
        canvas={true}
        name="l3Newspaper2"
        description="Who reads this trash?"
        speech={isBlueTeam ? 'stefanLevel3Newspaper' : 'allyLevel3Newspaper'}
      />
      <GameItem
        iconStyle={{ left: '6.2%', top: '66.3%', width: '40%', height: '23%', transform: 'rotate(-18deg)' }}
        imageSrc={isBlueTeam ? watches2 : watches1}
        canvas={true}
        hints={watchesHints}
        name="l3Watches1"
        description="A broken clock is always right twice a day but these have all stopped at different times!"
        speech={isBlueTeam ? 'stefanLevel3Watches' : 'allyLevel3Watches'}
      />
      <GameItem
        iconStyle={
          isBlueTeam
            ? { left: '58.7%', top: '35.7%', width: '20%', height: '14%', transform: 'rotate(-7deg)' }
            : { left: '58.7%', top: '35%', width: '18%', height: '14%', transform: 'rotate(-7deg)' }
        }
        imageSrc={isBlueTeam ? timeWillSetYouFree1 : timeWillSetYouFree2}
        canvas={true}
        hints={carvingHints}
        name="l3WordsOnWood"
        description={isBlueTeam ? 'Words carved into the wood, what does this refer to?' : 'Some letters carved into the wood, seems odd'}
        speech={isBlueTeam ? 'stefanLevel3TimeWillSet' : 'allyLevel3WoodCarving'}
      />
      <GameItem
        iconStyle={{ left: '8.7%', top: '36%', width: '27%', height: '25%', transform: 'rotate(-4.7deg)' }}
        imageSrc={isBlueTeam ? shipWheel : nonogram}
        canvas={true}
        hints={isBlueTeam ? shipWheelHints : nonogramHints}
        name="l3SteeringWheelOrNonogram"
        description={isBlueTeam ? 'A weathered piece of paper with some symbols and a ships wheel, hmmm…' : 'A weathered piece of paper with a note, hmmm…'}
        speech={isBlueTeam ? 'stefanLevel3Wheel' : 'allyLevel3PieceOfPaper'}
      />
      <Labyrinth />
    </div>
  );
};
