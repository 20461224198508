import { db, useFirestoreQuery } from '../../../firebase';
import { useAccessCode } from '../../../state';
import { useCurrentPlayer } from '../../../state/players';

type SlicesResult = [{
  blueAVisible: boolean;
  blueBVisible: boolean;
  redAVisible: boolean;
  redBVisible: boolean;
}, (color: 'red' | 'blue', visible: boolean) => void];

type QueryResponse = {
  key: 'blueAVisible' | 'blueBVisible' | 'redAVisible' | 'redBVisible';
  visible: boolean;
}[];

export function useSlices(): SlicesResult {
  const [{ code }] = useAccessCode();
  const [{ currentPlayer: { team }}] = useCurrentPlayer();

  const visibility = {
    blueAVisible: false,
    blueBVisible: false,
    redAVisible: false,
    redBVisible: false
  };

  const { data, status } = useFirestoreQuery<{key: string; visible: boolean}[]>(
    db.collection('game')
      .doc(code)
      .collection('slices')
  );

  if (status === 'success' && data) {
    data.forEach((slice: any) => {
      (visibility as any)[slice.key as any] = slice.visible;

    });
  }

  function setVisibility(color: 'red' | 'blue', visible: boolean) {
    let key = '';
    if (color === 'red') {
      key = `${team === 'red' ? 'redA' : 'redB'}Visible`;
    } else {
      key = `${team === 'blue' ? 'blueA' : 'blueB'}Visible`;
    }

    db.collection('game')
      .doc(code)
      .collection('slices')
      .doc(key)
      .set({ key, visible });
  }


  return [visibility, setVisibility];
}
