import { action, Action } from 'easy-peasy';

export interface App {
  level: null | string;
  secondTeamLevel: null | string;
}

export interface AppModel extends App {
  modalOpen: string | null;
}

export interface AppActions {
  setModalOpen: Action<AppState, boolean>;
}

export type AppState = AppModel & AppActions;

export const appState = {
  modalOpen: null,

  setModalOpen: action<AppState, string | null>((state, modalOpen) => {
    state.modalOpen = modalOpen;
  })
};
