import React, { FunctionComponent } from 'react';
import classnames from 'classnames';

import { useTimer, formatTimer } from '../../state';

import style from './GameBoard.module.css';

import timerBg from './assets/timer.png';
import timerBgBomb from './assets/timerBomb.png';
import timerBgPaused from './assets/timerPaused.png';

export const Timer: FunctionComponent<{}> = () => {
  const [{ mode, timer, paused }] = useTimer(true);
  const timerDanger = timer < 5 * 60 * 1000 && ['normal', 'none'].indexOf(mode) === -1;
  const isBombActivated = mode === 'bomb' || mode === 'bomb2';

  const timerBackground = (() => {
    if (paused) {
      return timerBgPaused;
    }
    if (isBombActivated) {
      return timerBgBomb;
    }
    return timerBg;
  })();

  return (
    <div
      className={classnames(style.timerContainer, paused && style.timerContainerPaused, !paused && isBombActivated && style.timerContainerBomb)}
      style={{ backgroundImage: `url(${timerBackground})` }}
    >
      <p className={classnames(style.timer, timerDanger && style.timerDanger)}>
        {timer ? formatTimer(timer) : '-'}
      </p>
      {(() => {
        if (paused) {
          return <p className={classnames(style.timerSecondLine, style.paused)}>PAUSED</p>;
        }
        return <p className={style.timerSecondLine} />;
      })()}
    </div>
  );
};
