import firebase from 'firebase';
import 'firebase/functions';
import 'firebase/database';
import 'firebase/firestore';

let firebaseConfig;

if (process.env.REACT_APP_ENV === 'development' || process.env.NODE_ENV === 'development') {
  firebaseConfig = {
    apiKey: 'AIzaSyB1dVEFjGSdj11qEtA32IUX2OQ3Yj7Z6NY',
    authDomain: 'boxed-detective-dev.firebaseapp.com',
    databaseURL: 'https://boxed-detective-dev.firebaseio.com',
    projectId: 'boxed-detective-dev',
    storageBucket: 'boxed-detective-dev.appspot.com',
    messagingSenderId: '200265736048',
    appId: '1:200265736048:web:d969f913188193ac2239b5',
    measurementId: 'G-20NXYX3L2R'
  };
} else {
  firebaseConfig = {
    apiKey: 'AIzaSyAkUzjooORMUsPCPBMhbw4lau9Ff_WZ_VA',
    authDomain: 'boxed-detective.firebaseapp.com',
    databaseURL: 'https://boxed-detective.firebaseio.com',
    projectId: 'boxed-detective',
    storageBucket: 'boxed-detective.appspot.com',
    messagingSenderId: '833493018132',
    appId: '1:833493018132:web:c5a702de1a9d31b6286901',
    measurementId: 'G-414TRC3VTW'
  };
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const db = firebase.firestore();

export const database = firebase.database();

export const functions = firebase.functions();
