import React from 'react';
import { useDrag, DragSourceMonitor } from 'react-dnd';

import style from './Beakers.module.css';

import beakerRed1 from './img/beakerRed1.png';
import beakerBlack1 from './img/beakerBlack1.png';
import beakerBlue1 from './img/beakerBlue1.png';
import beakerYellow1 from './img/beakerYellow1.png';
import beakerRed2 from './img/beakerRed2.png';
import beakerBlack2 from './img/beakerBlack2.png';
import beakerBlue2 from './img/beakerBlue2.png';
import beakerYellow2 from './img/beakerYellow2.png';

export const beakerImages = [
  beakerRed1,
  beakerBlack1,
  beakerBlue1,
  beakerYellow1,
  beakerRed2,
  beakerBlack2,
  beakerBlue2,
  beakerYellow2
];

export type Color = [number, number, number];

const YELLOW: Color = [255, 253, 0];
const BLUE: Color = [0, 173, 255];
const RED: Color = [255, 58, 23];
const BLACK: Color = [0, 0, 0];

export function getBeakerColorAndImg(name: string, isBlueTeam: boolean) {
  let beakerImg;
  let beakerColor: Color;

  switch (name) {
    case 'yellow':
      beakerImg = isBlueTeam ? beakerYellow1 : beakerYellow2;
      beakerColor = YELLOW;
      break;
    case 'red':
      beakerImg = isBlueTeam ? beakerRed1 : beakerRed2;
      beakerColor = RED;
      break;
    case 'white':
      beakerImg = isBlueTeam ? beakerBlack1 : beakerBlack2;
      beakerColor = BLACK;
      break;
    case 'blue':
      beakerImg = isBlueTeam ? beakerBlue1 : beakerBlue2;
      beakerColor = BLUE;
      break;
    default:
      beakerImg = isBlueTeam ? beakerBlack1 : beakerBlack2;
      beakerColor = BLACK;
      break;
  }

  return { beakerImg, beakerColor };
}

interface BeakerImageProps {
  name: string;
  isBlueTeam: boolean;
}

export const BeakerImage: React.FC<BeakerImageProps> = ({ name, isBlueTeam }) => {
  const { beakerImg } = getBeakerColorAndImg(name, isBlueTeam);
  return (
    <div
      style={{ backgroundImage: `url(${beakerImg})` }}
      className={style.beaker}
    />
  );
};

interface BeakerProps {
  name: string;
  onDrop: (color: Color) => void;
  isBlueTeam: boolean;
}

export const Beaker: React.FC<BeakerProps> = ({ name, onDrop, isBlueTeam }) => {
  const { beakerColor } = getBeakerColorAndImg(name, isBlueTeam);

  const [{ isDragging }, drag] = useDrag({
    item: { name, type: 'beaker', isBlueTeam },
    end: (item: { name: string } | undefined, monitor: DragSourceMonitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onDrop(beakerColor);
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;

  return (
    <div ref={drag} style={{ opacity }} className={style.beakerDrag}>
      <BeakerImage name={name} isBlueTeam={isBlueTeam} />
    </div>
  );
};
