import React, { useState } from 'react';
import classnames from 'classnames';
import { Button, Modal } from 'antd';

import { formatTimer, useCurrentPlayer, useGame, useTimer } from '../../../state';
import { GameItem, sounds } from '../../../components';
import { Wires } from './Wires';
import { useBombLevel } from '../state';
import { useWaveform } from '../Waveform/state';

import style from './Bomb.module.css';

import bomb from './img/bomb.png';

const bombHints = [
  'This puzzle requires information from many of the puzzles in the boxes from both teams. You must get this information before you can do anything with the bomb.',
  'The wire positioning will come from completing the sliding puzzles.',
  'The voltages for each wire will come from the rest of the puzzles. Complete them to defuse the bomb (or use the individual puzzles clues to help you).',
  'The solution is 7358 (green, white, red, blue).'
];

export const bombImages = [
  bomb
];

interface NumberSpinnerProps {
  i: number;
  value: number;
  disabled: boolean;
  onUp: (i: number) => void;
  onDown: (i: number) => void;
}

const NumberSpinner = ({ i, value, onUp, onDown, disabled }: NumberSpinnerProps) => (
  <>
    <div
      className={classnames(style.arrowUp, style[`arrowUp${i}`], disabled && style.arrowDisabled)}
      onClick={() => !disabled && onUp(i)}
    >^
    </div>
    <div
      className={classnames(style.arrowDown, style[`arrowDown${i}`], disabled && style.arrowDisabled)}
      onClick={() => !disabled && onDown(i)}
    >^
    </div>
    <span className={classnames(style.number, style[`number${i}`])}>{value}</span>
  </>
);

const SubmitButton = ({ onSubmit }: { onSubmit: () => void }) => (
  <div className={style.buttonContainer}>
    <Button
      size="large"
      className={style.submitButton}
      onClick={onSubmit}
    >
      Defuse
    </Button>
  </div>
);

interface TimerProps {
  timeLeft: number;
  bombStarted: boolean;
}

const Timer = ({ bombStarted, timeLeft }: TimerProps) => (
  <div className={style.timer}>
    {bombStarted ? (timeLeft > 0 ? formatTimer(timeLeft) : '') : 'BOMB CONTROL'}
  </div>
);

interface BombProps {
  onDisabledClick: () => void;
}

export const Bomb = (props: BombProps) => {
  const [{ data }, { setBombStart }] = useGame();
  const [{ currentPlayer: { team } }] = useCurrentPlayer();
  const [{ timer, mode }] = useTimer();
  const isBlueTeam = team === 'blue';
  const [value, setValue] = useState([0, 0, 0, 0]);
  const [wires, { setWires, resetBombSequence }] = useBombLevel();
  const [{ opened: wasWaveformOpened }] = useWaveform();

  const bombActivated = !!(data?.bombStartedAt);

  const onUp = (i: number) => {
    const newValue = [...value];
    newValue[i] = (newValue[i] + 1) % 10;
    setValue(newValue);
    sounds.beep();
  };

  const onDown = (i: number) => {
    const newValue = [...value];
    newValue[i] = (10 + (newValue[i] - 1)) % 10;
    setValue(newValue);
    sounds.beep();
  };

  const onSubmit = () => {
    Modal.confirm({
      title: 'Are you sure?',
      icon: null,
      content: 'It might have consequences for ALL players!',
      okText: 'Yes, let\'s go!',
      cancelText: 'Let me think about it...',
      centered: false,
      style: {
        top: '200px'
      },
      maskClosable: true,
      className: classnames(style.confirmModal, 'game-confirm'),
      onOk: () => {
        const wiresAreOk = wires.b === 4 && wires.g === 1 && wires.r === 3 && wires.w === 2;
        if (wiresAreOk && (value.join('') === '7358')) {
          setBombStart();
          sounds.alarm();
          setTimeout(() => {
            // set valid wires combination for both teams
            // will be required later
            setWires({ b: 4, g: 1, r: 3, w: 2 }, 'blue');
            setWires({ b: 4, g: 1, r: 3, w: 2 }, 'red');
          }, 5000);
        } else {
          sounds.error();
        }
      }
    });
  };

  return (
    <GameItem
      iconStyle={
        isBlueTeam
          ? { left: '31%', top: '32%', width: '48%', height: '40%' }
          : { left: '26%', top: '32%', width: '48%', height: '40%' }
      }
      imageSrc={bomb}
      canvas={false}
      name="l5Bomb"
      description={'It\'s a BOMB!'}
      hints={bombHints}
      speech={isBlueTeam ? 'stefanLevel5Bomb' : 'allyLevel5Bomb'}
      onReset={() => resetBombSequence()}
      disabled={bombActivated && !wasWaveformOpened}
      onClick={() => {
        if (bombActivated && !wasWaveformOpened) {
          props.onDisabledClick();
        }
      }}
    >
      <Timer bombStarted={bombActivated && (mode === 'bomb' || mode === 'bomb2')} timeLeft={timer} />
      <Wires />
      <NumberSpinner i={0} value={value[0]} onUp={onUp} onDown={onDown} disabled={bombActivated} />
      <NumberSpinner i={1} value={value[1]} onUp={onUp} onDown={onDown} disabled={bombActivated} />
      <NumberSpinner i={2} value={value[2]} onUp={onUp} onDown={onDown} disabled={bombActivated} />
      <NumberSpinner i={3} value={value[3]} onUp={onUp} onDown={onDown} disabled={bombActivated} />
      {!bombActivated && <SubmitButton onSubmit={onSubmit} />}
    </GameItem>
  );
};
