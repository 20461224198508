/* eslint no-param-reassign: "off" */
// https://easy-peasy.now.sh/docs/quick-start.html
import { action } from 'easy-peasy';

type CanvasState = {
  drawings: {
    [key: string]: string;
  };
};

type SetPayload = {
  key: string;
  drawing: string;
}

export const initialState: CanvasState = {
  drawings: {}
};

export const actions = {
  set: action<CanvasState, SetPayload>((state, { key, drawing }) => {
    state.drawings[key] = drawing;
  })
};
