import React from 'react';
import { Input, Form, Button, Tag, Divider } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import classnames from 'classnames';

import { ErrorPage } from '../ErrorPage';
import { WaitingLevel } from './WaitingLevel';
import { Loading } from '../Loading';
import { Player, usePlayers, useCurrentPlayer, useAccessCode, useDebugMode, useGame } from '../state';
import { IntroLevel, Level2, Level3, Level4, BombLevel, Congratulations } from '../levels';
import { GameBoard, speech, MagicFrame } from '../components';
import style from './WaitingRoom.module.css';

import manualDevice from './assets/manualDevice.png';
import manualFood from './assets/manualFood.png';
import manualNotes from './assets/manualNotes.png';
import speech1 from './assets/speech1.png';

import ally from './assets/ally.jpg';
import stefan from './assets/stefan.jpg';

function canStartGame(currentPlayer: Player, players?: Player[]) {
  if (!players) return false;
  let bluePresent = false;
  let redPresent = false;
  for (const player of players) {
    if (player.team === 'blue') {
      bluePresent = true;
    } else if (player.team === 'red') {
      redPresent = true;
    }
  }
  return bluePresent && redPresent && currentPlayer.team;
}

const MainPage = () => {
  const { data: players, status, error } = usePlayers();
  const [{ data: game }, { setGameStart }] = useGame();
  const [{ currentPlayer, loading }, { setCurrentPlayer, changeLevel }] = useCurrentPlayer();
  const [{ code }, { isCorp }] = useAccessCode();
  const [{ debugMode }] = useDebugMode();
  const level = currentPlayer?.level;

  const canStart = canStartGame(currentPlayer, players);
  const isLoading = loading || status === 'loading' || !code;

  function startGame() {
    setCurrentPlayer({ started: true });
    if (!game?.startedAt) {
      setGameStart(); // start the timer for ALL PLAYERS
    }
    if (!level) {
      changeLevel(game?.level || '1-waiting');
    }
    setTimeout(() => window.scrollTo(0, 0), 500);
  }

  const setPlayer = ({ team }: { team: string }) => {
    setCurrentPlayer({ team });
    if (team === 'blue') {
      speech.say('stefanLevel0Picking', { force: true });
    } else {
      speech.say('allyLevel0Picking', { force: true });
    }
  };

  if (error) return <ErrorPage />;
  if (isLoading) return <Loading />;

  const main = (
    <MagicFrame className={style.page} contentClassName={style.pageContent}>
      {status !== 'success' ? <LoadingOutlined /> : (
        <>
          <h1>GAME PREPARATION</h1>

          <Divider className={style.divider}>Step 1</Divider>
          <img src={manualDevice} alt="call" style={{ width: 400, marginBottom: '50px' }} />
          <p>The game requires at least 2 players, each players needs a different device.</p>
          <p>You will need to communicate heavily throughout the game so use a video-conferencing tool (eg., Skype, Zoom, FaceTime or Google Hangouts)</p>

          <Divider className={style.divider}>Step 2</Divider>
          <div className={style.soundWrapper}>
            <div className={style.sound} onClick={() => speech.say('stefanLevel0SoundCheck', { force: true })}>
              <img src={speech1} alt="speech" style={{ width: 100 }} />
              <p>Play</p>
            </div>
          </div>
          <p>During the game the detectives will have optional audio you can activate by clicking the icon.</p>
          <p>Make sure you have sound on. Click on the above icon to test it.</p>

          <Divider className={style.divider}>Step 3</Divider>
          <img src={manualNotes} alt="penAndPaper" style={{ width: 150, marginBottom: '40px' }} />
          <p>Make sure you have some pen and paper to hand. The game will be easier if you do take a few notes here and there.</p>

          <Divider className={style.divider}>Step 4</Divider>
          <img src={manualFood} alt="food" style={{ width: 250, marginBottom: '40px' }} />
          <p>The game will last around {isCorp() ? '60' : '90'} mins, so take the time to go to the toilet or grab some snacks if you wish!</p>

          <Divider className={style.divider}>Step 5</Divider>

          <Form
            layout="horizontal"
            onFinish={() => startGame()}
            initialValues={{
              name: currentPlayer.name,
              team: currentPlayer.team
            }}
          >
            <p>Which detective you want to help?</p>

            <div className={style.characterSelect}>
              <div
                className={classnames(style.ally, currentPlayer.team === 'red' ? style.selected : '')}
                onClick={() => setPlayer({ team: 'red' })}
                data-cy="select-red"
              >
                <img src={ally} alt="Ally" />
                <p className={style.name}>Allison Abernathy</p>
                <p className={style.nicknameAlly}>Ally</p>
                <p>Police blood runs through her veins. <br />Glasgow, Scotland</p>
              </div>
              <div
                className={classnames(style.stefan, currentPlayer.team === 'blue' ? style.selected : '')}
                onClick={() => setPlayer({ team: 'blue' })}
                data-cy="select-blue"
              >
                <img src={stefan} alt="Stefan" />
                <p className={style.name}>Steve Kowalski</p>
                <p className={style.nicknameStefan}>Old Dog</p>
                <p>Police commissioner married to the job. <br />Krakow, Poland</p>
              </div>
            </div>

            <Divider className={style.divider}>Step 6</Divider>

            <p>Please enter your name:</p>

            <Form.Item
              name="name"
              className={style.nameInput}
              rules={[{
                required: true,
                message: 'Please input the name'
              }, {
                whitespace: true,
                message: 'Don\'t try this, smart pants!'
              }, {
                max: 32,
                message: 'Is it really that long?'
              }]}
            >
              <Input
                autoComplete="off"
                onChange={ev => setCurrentPlayer({ name: ev.target.value })}
              />
            </Form.Item>

            <p>Wait for other players to join. You need at least one player for each team.</p>
            <p>Players online:</p>

            <ul className={style.playerList}>
              {players?.map(player => (
                <li key={player.id}>
                  {player.name ? player.name : '<Unnamed player>'}{' '}
                  {player.team ? <Tag style={{ fontSize: '1em', padding: '5px 10px' }} color="#754c2c">{player.team === 'blue' ? 'Old Dog' : 'Ally'}</Tag> : ''}
                  {player.id === currentPlayer.id ? '(You)' : ''}
                </li>
              ))}
            </ul>

            <Divider className={style.divider}>Step 7</Divider>

            <p>Hit the button below to start the game and enjoy yourself!</p>

            <Button type="primary" size="large" disabled={!canStart} htmlType="submit">{canStart ? 'Continue' : 'You need at least one player for each team'}</Button>
          </Form>
        </>
      )}
    </MagicFrame>
  );

  switch ((currentPlayer.started || debugMode) ? `${level}` : 'default') {
    case '1-waiting':
      return <GameBoard><WaitingLevel nextLevel="1" /></GameBoard>;
    case '1':
      return <GameBoard><IntroLevel /></GameBoard>;
    case '2-waiting':
      return <GameBoard><WaitingLevel nextLevel="2" /></GameBoard>;
    case '2':
      return <GameBoard><Level2 /></GameBoard>;
    case '3-waiting':
      return <GameBoard><WaitingLevel nextLevel="3" /></GameBoard>;
    case '3':
      return <GameBoard><Level3 /></GameBoard>;
    case '4-waiting':
      return <GameBoard><WaitingLevel nextLevel="4" /></GameBoard>;
    case '4':
      return <GameBoard><Level4 /></GameBoard>;
    case '5-waiting':
      return <GameBoard><WaitingLevel nextLevel="5" /></GameBoard>;
    case '5':
      return <GameBoard><BombLevel /></GameBoard>;
    case '6':
      return <Congratulations />;
    default:
      return main;
  }
};

export default MainPage;
