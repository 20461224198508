import React, { FunctionComponent } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import classnames from 'classnames';
import { GameModal } from '../GameModal';
import { useCurrentPlayer } from '../../state';

import style from './GameItem.module.css';

interface GameItemProps {
  iconSrc?: string;
  imageSrc?: string;
  canvas?: boolean;
  canvasColor?: string;
  name: string;
  description: string;
  iconStyle?: React.CSSProperties;
  iconClassName?: string;
  destroyOnClose?: boolean;
  hints?: React.ReactNode[];
  disabled?: boolean;
  speech?: string;
  onClick?: () => void;
  onReset?: () => void; // reset puzzle, only for debugging
}

export const GameItem: FunctionComponent<GameItemProps> = (props) => {
  const modalVisible = useStoreState(state => state.app.modalOpen) === props.name;
  const setModalVisible = useStoreActions((state: any) => state.app.setModalOpen);
  const [, { logAction }] = useCurrentPlayer();

  function onOpen() {
    if (!props.disabled) {
      setModalVisible(props.name);
    }
    props.onClick && props.onClick();
    logAction('open', props.name);
  }

  function onClose() {
    setModalVisible(null);
    logAction('close', props.name);
  }

  return (
    <>
      {props.iconSrc ? (
        <img
          data-cy={props.name}
          src={props.iconSrc}
          alt={props.name}
          className={classnames(style.item, props.iconClassName)}
          onClick={onOpen}
          style={props.iconStyle}
        />
      ) : (
        <div
          data-cy={props.name}
          className={style.item}
          onClick={onOpen}
          style={props.iconStyle}
        />
      )}
      <GameModal
        visible={modalVisible}
        onCloseModal={onClose}
        canvas={props.canvas}
        canvasColor={props.canvasColor}
        name={props.name}
        description={props.description}
        imageSrc={props.imageSrc}
        destroyOnClose={props.destroyOnClose}
        hints={props.hints || []}
        onReset={props.onReset}
        speech={props.speech}
      >
        {props.children}
      </GameModal>
    </>
  );
};
