import React from 'react';
import { GameItem, GameLock, sounds } from '../../components';
import { Flashlight, flashlightImages } from './Flashlight';
import { Player, playerImages } from './Player';
import { AnimalDevice, animalDeviceImages } from './AnimalDevice';
import { Beakers, beakersImages } from './Beakers';
import { useCurrentPlayer } from '../../state';
import { useKarolinaLevel } from './state';

import style from './Level4.module.css';

import tickets from './img/tickets.png';
import map from './img/map.png';
import newspaper1 from './img/newspaper1.png';
import newspaper2 from './img/newspaper2.png';
import periodicTable1 from './Beakers/img/periodicTable1.png';
import periodicTable2 from './Beakers/img/periodicTable2.png';
import colorMixer1 from './img/colorMixer1.png';
import colorMixer2 from './img/colorMixer2.png';
import powerLockOff from './img/powerLockOff.png';
import powerLockOn from './img/powerLockOn.png';
import transitionOldDog from './img/transitionOldDog.jpg';
import transitionAlly from './img/transitionAlly.jpg';

export const level4Images = [
  '/img/l4Red1.jpg',
  '/img/l4Blue1.jpg',
  '/img/l4Red2.jpg',
  '/img/l4Blue2.jpg',
  tickets,
  map,
  newspaper1,
  newspaper2,
  periodicTable1,
  periodicTable2,
  colorMixer1,
  colorMixer2,
  powerLockOff,
  powerLockOn,
  transitionOldDog,
  transitionAlly,
  ...animalDeviceImages,
  ...beakersImages,
  ...flashlightImages,
  ...playerImages
];

const colorMixerSolutions = [
  // navy blue, dark green, orange, violet
  { combination: '5713', correct: true }
];

const powerLockSolutions = [
  { combination: '5374', correct: true }
];

const lockHints = [
  'This lock needs power before you can put in numbers. When you find out a way to turn on the power, then the black box will reveal the order for the numbers to be put into the lock.',
  'When powered the lock shows a shell (to represent the sound puzzle with animals), a video icon (to represent the video from Ally’s team\'s box), a flashlight (to represent the blinking flashlight from Old Dog’s team\'s box) and an airplane (to represent the puzzle using the map and plane tickets). Putting in the answers from these puzzles in that order will open the lock.',
  'The answer is 5374.'
];

const powerLockHints = [
  'This puzzle requires several pieces to complete, the mixing flasks from both teams, the half of a periodic table found in each teams’ box and the power device itself.',
  'Use the periodic table to work out which elements are in your flasks.',
  'Input the colours that come out from mixing the colours linked to each point in the power device.',
  'The colours you get should come out as dark blue, dark green, orange, grey (some may see this as dark purple?!).'
];

const periodicHints = [
  'The periodic table is required to complete the power device connected to the lock alongside the flasks. You have half of the periodic table each, so you may have to work together to find the information you need.'
];

const newspaper1Hints = [
  'This newspaper will help in solving the blinking flashlight.',
  'The flashlight is blinking in Morse code. Can you catch the sequence and check out the Morse code here to give you a number?',
  'The flashlight is blinking in the following sequence _ _ . . . which gives the number 7.'
];

const newspaper2Hints = [
  'This newspaper is required to solve the flight puzzle.',
  'If you notice that there were flights cancelled due to coronavirus, this may help you with the flight puzzle.'
];

const ticketsHints = [
  'The flight tickets are part of a puzzle which also requires the map found in Old Dog’s team\'s box, using the newspaper in Old Dog’s team\'s box may also reveal some relevant information.',
  'Working out the order the flights went in, relay the information to Old Dog’s team so they can connect the locations on the map, beware not all tickets were valid.',
  'After connecting the locations of the flight tickets that were not cancelled due to coronavirus (info found on Old Dog’s team\'s paper) you should be left with a number.',
  'The lines should reveal a number 4.'
];

const mapHints = [
  'The map is part of a puzzle which also requires the flight tickets found in Ally’s team\'s’ box. Using the newspaper in Old Dog’s team\'s box may also reveal some relevant information.',
  'Ally’s team must work out the order the flights went in and relay the information to Old Dog’s team so they can connect the locations on the map. Beware, not all tickets were valid.',
  'After connecting the locations of the flight tickets that were not cancelled due to coronavirus (info found on Ally’s team\'s paper) you should be left with a number.',
  'The lines should reveal a number 4.'
];

export const Level5Waiting = () => {
  const [{ currentPlayer: { team } }] = useCurrentPlayer();
  const isBlueTeam = team === 'blue';

  const transitionImageSrc = isBlueTeam ? transitionOldDog : transitionAlly;

  return (
    <>
      <h1>Wait, you completed another level? It was supposed to be hard!</h1>
      <p><img src={transitionImageSrc} alt="Opening the bottom" /></p>
      <p>The bottom of the box has fallen out to reveal a hole in the ground filled with more items.</p>
      <p>Is that a ticking sound you hear?</p>
    </>
  );
};

export const Level4 = () => {
  const [{ currentPlayer: { team } }, { changeLevel }] = useCurrentPlayer();
  const isBlueTeam = team === 'blue';
  const [{ power }, { setPower }] = useKarolinaLevel();

  return (
    <div className={style.page}>
      <AnimalDevice />
      <Beakers />
      {isBlueTeam ? <Flashlight /> : <Player />}
      <GameItem
        iconStyle={
          isBlueTeam
            ? { left: '18%', top: '30%', width: '28.9%', height: '30.5%', transform: 'rotate(-1deg)' }
            : { left: '11%', top: '31%', width: '34%', height: '31%', transform: 'rotate(-1deg)' }
        }
        imageSrc={isBlueTeam ? map : tickets}
        canvas={true}
        hints={isBlueTeam ? mapHints : ticketsHints}
        name={isBlueTeam ? 'l4Map' : 'l4Tickets'}
        description={isBlueTeam ? 'A European map showing various cities. Is this linked to the killer?' : 'Various plane tickets. Was the killer trying to avoid capture or looking for new victims?'}
        speech={isBlueTeam ? 'stefanLevel4Map' : 'allyLevel4PlaneTickets'}
      />
      <GameItem
        iconStyle={{ left: '63%', top: '25%', width: '37%', height: '25%', transform: 'rotate(7deg)' }}
        imageSrc={isBlueTeam ? newspaper2 : newspaper1}
        hints={isBlueTeam ? newspaper2Hints : newspaper1Hints}
        canvas={true}
        name={isBlueTeam ? 'l4Newspaper1' : 'l4Newspaper2'}
        description={isBlueTeam ? 'Glasgow News, the killers hitting the headlines again, when will it stop?' : 'Krakow Times, more senseless killing, when will it stop?'}
        speech={isBlueTeam ? 'stefanLevel4newspaper' : 'allyLevel4newspaper'}
      />
      <GameItem
        iconStyle={{ left: '71.5%', top: '0%', width: '28%', height: '21%', transform: 'rotate(-37deg)' }}
        imageSrc={isBlueTeam ? periodicTable2 : periodicTable1}
        canvas={true}
        hints={periodicHints}
        name={!isBlueTeam ? 'Left part of the periodic table' : 'Right part of the periodic table'}
        description={!isBlueTeam ? 'Left part of the periodic table' : 'Right part of the periodic table'}
        speech={isBlueTeam ? 'stefanLevel4PeriodicTable' : 'allyLevel4PeriodicTable'}
      />
      <GameLock
        type="colors"
        iconStyle={{ left: '-5.4%', top: '-1%', width: '22%', height: '29%' }}
        imageSrc={isBlueTeam ? colorMixer1 : colorMixer2}
        initialValue={[7, 2, 4, 8]}
        name="l4ColorMixerLock"
        description={power ? 'The power is back!' : 'Looks like I need to turn this on to power the lock.'}
        numberContainerStyle={{ top: '15%' }}
        solutions={colorMixerSolutions}
        submitText="Turn on"
        disabled={power}
        hints={powerLockHints}
        onSolved={() => {
          sounds.powerUp();
          setTimeout(() => setPower(true), 1000);
        }}
        speech={isBlueTeam ? 'stefanLevel4powerBox' : 'allyLevel4powerBox'}
      />
      <GameLock
        type="numbers"
        iconStyle={{ left: '28.5%', top: '3.6%', width: '30.5%', height: '28%' }}
        imageSrc={power ? powerLockOn : powerLockOff}
        name={power ? 'l4PowerLockOn' : 'l4PowerLockOff'}
        description={power ? 'The power is back!' : 'A lock, maybe getting power to it will help…'}
        numberContainerStyle={{ top: '23%' }}
        solutions={powerLockSolutions}
        disabled={!power}
        hints={lockHints}
        onSolved={() => {
          sounds.success2();
          setTimeout(() => {
            changeLevel('5-waiting');
          }, 1000);
        }}
        speech={(() => {
          if (power) {
            return isBlueTeam ? 'stefanLevel4LockAfterPower' : 'allyLevel4LockAfterPower';
          }
          return isBlueTeam ? 'stefanLevel4LockBeforePower' : 'allyLevel4LockBeforePower';
        })()}
      />
    </div>
  );
};
