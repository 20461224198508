import React from 'react';
import Draggable from 'react-draggable';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useCurrentPlayer } from '../../../state';
import { GameItem } from '../../../components';
import { slices } from './slices';

import bg from './img/bg.png';

import style from './Puzzle.module.css';

export const puzzleImages = [
  ...slices
];

const puzzleHints = [
  'This puzzle requires completion of both the jigsaw puzzles.',
  'C’mon lazy bones it’s not hard to do a jigsaw… Anything left over?',
  'The spare piece in each puzzle will give you a colour (green) and number (7) to help defuse the bomb.'
];

interface JigsawPieceProps {
  sliceSrc: string;
  pieceName: string;
}

const JigsawPiece = ({ sliceSrc, pieceName }: JigsawPieceProps) => {
  // use controlled component to prevent the pieces position resetting
  const savedPosition = useStoreState(state => state.puzzle[pieceName]);
  const savePosition = useStoreActions((state: any) => state.puzzle.set);
  const setPosition = ({ x, y }: { x: number; y: number }) => savePosition({ key: pieceName, x, y });
  const position = savedPosition || { x: 0, y: 0 };

  return (
    <Draggable
      key={pieceName}
      position={position}
      onStart={(e, data) => setPosition({ x: data.x, y: data.y })}
      onDrag={(e, data) => setPosition({ x: data.x, y: data.y })}
      onStop={(e, data) => setPosition({ x: data.x, y: data.y })}
    >
      <img
        key="puzzle"
        className={style.puzzle}
        src={sliceSrc}
        alt={pieceName}
        onDragStart={
          // fix Firefox drag bug
          (e: any) => e.preventDefault()
        }
      />
    </Draggable>
  );
};

const Puzzles = () => {
  const [{ currentPlayer: { team } }] = useCurrentPlayer();
  const isBlueTeam = team === 'blue';
  let offset = isBlueTeam ? 0 : 25;
  const pieces = [];
  for (let i = 0; i < 26; i++) {
    const pieceName = `slice${i}`;
    if (i === 25) {
      offset = isBlueTeam ? 25 : 26; // adjust for additional piece
    }
    pieces.push(
      <JigsawPiece
        key={pieceName}
        sliceSrc={slices[i + offset] as string}
        pieceName={pieceName}
      />
    );
  }
  return (
    <div className={style.puzzleBackground} style={{ backgroundImage: `url(${bg})` }}>
      {pieces}
    </div>
  );
};

interface PuzzleProps {
  disabled?: boolean;
  onClick?: () => void;
  speech?: string;
}

export const Puzzle = (props: PuzzleProps) => {
  const [{ currentPlayer: { team } }] = useCurrentPlayer();
  const isBlueTeam = team === 'blue';
  return (
    <GameItem
      disabled={props.disabled}
      onClick={props.onClick}
      iconStyle={
        isBlueTeam
          ? { left: '75%', top: '76%', width: '23.5%', height: '24%' }
          : { left: '74%', top: '72%', width: '24%', height: '25%' }
      }
      canvas={false}
      name="k5PuzzleDevice"
      hints={puzzleHints}
      description="Something seems off about these puzzles."
      destroyOnClose={false}
      speech={props.speech}
    >
      <Puzzles />
    </GameItem>
  );
};
