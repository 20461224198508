import React from 'react';
import { Stage, Layer, Line, Circle } from 'react-konva';

import { useCurrentPlayer } from '../../../state';
import { GameItem, CartoonButton } from '../../../components';
import { useLabyrinth } from './state';

import style from './Labyrinth.module.css';

import labyrinthLeft from './img/labyrinthLeft.png';
import labyrinthUp from './img/labyrinthUp.png';
import buttonLeftUp from './img/buttonLeftUp.png';
import buttonLeftHover from './img/buttonLeftHover.png';
import buttonLeftDown from './img/buttonLeftDown.png';
import buttonRightUp from './img/buttonRightUp.png';
import buttonRightHover from './img/buttonRightHover.png';
import buttonRightDown from './img/buttonRightDown.png';
import buttonUpUp from './img/buttonUpUp.png';
import buttonUpHover from './img/buttonUpHover.png';
import buttonUpDown from './img/buttonUpDown.png';
import buttonDownUp from './img/buttonDownUp.png';
import buttonDownHover from './img/buttonDownHover.png';
import buttonDownDown from './img/buttonDownDown.png';

export const labyrinthImages = [
  labyrinthLeft,
  labyrinthUp,
  buttonLeftUp,
  buttonLeftHover,
  buttonLeftDown,
  buttonRightUp,
  buttonRightHover,
  buttonRightDown,
  buttonUpUp,
  buttonUpHover,
  buttonUpDown,
  buttonDownUp,
  buttonDownHover,
  buttonDownDown
];

const CANVAS_WIDTH = 365;
const CANVAS_HEIGHT = 365;
const FIELD_WIDTH = CANVAS_WIDTH / 10;
const FIELD_HEIGHT = CANVAS_HEIGHT / 10;

const hints = [
  'This puzzle requires this device and the same device on the other team.',
  'Use the arrows to move the dot on the screen to form a shape of some sort. If you press the wrong direction, you will have to start from the start, so try to remember where you go wrong when it disappears and start again.',
  'The puzzle starts with three right presses followed by 3 down presses, cooperate to work out what comes next, a big hint, you are going to form a number here.',
  'The number you are trying to complete is the number 4 (3 right, 3 down, 1 right, 7 up, 1 left, 3 down, 2 left, 3 up, 1 left, 4 down).'
];

export const Labyrinth = () => {
  const [{ currentPlayer: { team } }] = useCurrentPlayer();
  const isBlueTeam = team === 'blue';
  const [{ input: lines }, onMove] = useLabyrinth();

  const points = [0, 0];

  for (const line of lines) {
    const x = points[points.length - 2];
    const y = points[points.length - 1];
    const direction = line[0];
    const distance = line[1];

    if (direction === 'left') {
      points.push(x - FIELD_WIDTH * distance);
      points.push(y);
    } else if (direction === 'right') {
      points.push(x + FIELD_WIDTH * distance);
      points.push(y);
    } else if (direction === 'up') {
      points.push(x);
      points.push(y - FIELD_HEIGHT * distance);
    } else if (direction === 'down') {
      points.push(x);
      points.push(y + FIELD_HEIGHT * distance);
    }
  }

  return (
    <GameItem
      iconStyle={{ left: '48%', top: '57.3%', width: '39%', height: '37%', transform: 'rotate(-10deg)' }}
      canvas={false}
      name="l3Labyrinth"
      speech={isBlueTeam ? 'stefanLevel3ElectronicPuzzle' : 'allyLevel3ElectronicPuzzle'}
      hints={hints}
      description="Some of these buttons aren’t working, how am I supposed to use this?"
    >
      <div className={style.container}>
        <Stage className={style.stage} height={365} width={365}>
          <Layer>
            <Circle
              x={CANVAS_WIDTH - FIELD_WIDTH / 2 - FIELD_WIDTH * 7}
              y={FIELD_WIDTH * 5 + FIELD_WIDTH / 2 - 2}
              radius={12}
              fill="#f73206"
            />
            <Line
              x={CANVAS_WIDTH - FIELD_WIDTH / 2 - FIELD_WIDTH * 7}
              y={FIELD_WIDTH * 5 + FIELD_WIDTH / 2 - 2}
              points={points}
              stroke="#f73206"
              strokeWidth={16}
              lineJoin="round"
              lineCap="round"
            />
          </Layer>
        </Stage>
        <img className={style.background} src={isBlueTeam ? labyrinthLeft : labyrinthUp} alt="labyrinth" />
        {!isBlueTeam && (
          <>
            <CartoonButton src={buttonUpUp} hoverSrc={buttonUpHover} activeSrc={buttonUpDown} onClick={() => onMove('up')} style={{ top: '206px', left: '607px', width: '62px' }} />
            <CartoonButton src={buttonDownUp} hoverSrc={buttonDownHover} activeSrc={buttonDownDown} onClick={() => onMove('down')} style={{ top: '322px', left: '607px', width: '62px' }} />
          </>
        )}
        {isBlueTeam && (
          <>
            <CartoonButton src={buttonLeftUp} hoverSrc={buttonLeftHover} activeSrc={buttonLeftDown} onClick={() => onMove('left')} style={{ top: '265px', left: '549px', width: '66px' }} />
            <CartoonButton src={buttonRightUp} hoverSrc={buttonRightHover} activeSrc={buttonRightDown} onClick={() => onMove('right')} style={{ top: '265px', left: '664px', width: '67px' }} />
          </>
        )}
      </div>
    </GameItem>
  );
};
