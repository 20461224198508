import { db, useFirestoreQuery } from '../../firebase';
import { useAccessCode, useCurrentPlayer } from '../../state';

type KarolinaLevelResult = [{ power: boolean }, { setPower: (power: boolean) => void }];

export function useKarolinaLevel(): KarolinaLevelResult {
  const [{ code, loading: codeLoading }] = useAccessCode();
  const [{ currentPlayer: { team }, loading: playerLoading }] = useCurrentPlayer();
  const loading = playerLoading || codeLoading;

  const { data } = useFirestoreQuery<{ power: boolean }>(
    !loading && db.collection('game')
      .doc(code || '-')
      .collection('karolinaLevel')
      .doc(team)
  );

  function setPower(power: boolean) {
    db.collection('game')
      .doc(code)
      .collection('karolinaLevel')
      .doc(team)
      .set({ power });
  }

  return [{ power: !!data?.power }, { setPower }];
}
