import React from 'react';
import classnames from 'classnames';

import style from './GameLock.module.css';

interface NumberSpinnerProps {
  i: number;
  value: number;
  onUp: (i: number) => void;
  onDown: (i: number) => void;
  img: string;
  disabled?: boolean;
}

function generateImg(img: string, value: number) {
  const zind = value % 30;
  const zIndex = zind >= 15 && zind < 25 ? 0 : 3;
  const standardOffset = -80 * value;
  const bigOffset = Math.floor((value + 10) / 30) * 2400;
  const translateY = standardOffset + bigOffset;

  return (
    <img
      src={img}
      alt="number"
      style={{
        transform: `translateY(${translateY}px)`,
        zIndex
      }}
    />
  );
}

export const NumberSpinner = ({ i, value, onUp, onDown, img, disabled }: NumberSpinnerProps) => (
  <div>
    {!disabled && (
      <>
        <div
          className={classnames(style.arrowUp, style[`number${i}`])}
          onClick={() => onUp(i)}
        >^
        </div>
        <div
          className={classnames(style.arrowDown, style[`number${i}`])}
          onClick={() => onDown(i)}
        >^
        </div>
      </>
    )}
    <div
      className={classnames(style.number, style[`number${i}`], disabled ? style.disabled : '')}
    >
      {generateImg(img, value + 10000 - 10)}
      {generateImg(img, value + 10000)}
      {generateImg(img, value + 10000 + 10)}
    </div>
  </div>
);
