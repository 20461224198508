import React, { useState } from 'react';
import { useStoreActions } from 'easy-peasy';
import classnames from 'classnames';
import { Button } from 'antd';
import { useCurrentPlayer } from '../../../state';
import { GameItem, sounds } from '../../../components';

import style from './Slider.module.css';

import sliderChestOpen from './img/sliderChestOpen.png';
import sliderSolution1 from './img/sliderSolution1.png';
import sliderSolution2 from './img/sliderSolution2.png';

import playArea from './img/playArea.png';
import brick1x3 from './img/brick1x3.png';
import brick1x2 from './img/brick1x2.png';
import brick2x1 from './img/brick2x1.png';
import brick3x1 from './img/brick3x1.png';
import brick1x2Special from './img/brick1x2Special.png';

export const sliderImages = [
  sliderChestOpen,
  sliderSolution1,
  sliderSolution2,
  playArea,
  brick1x3,
  brick1x2,
  brick2x1,
  brick3x1,
  brick1x2Special
];

const sliderOpenHints = [
  'The photos should show that the green wire goes into the top spot, white second, red third, and blue last.'
];

const GRID = [6, 6];
const elementWidth = 100 / GRID[0];
const elementHeight = 100 / GRID[1];

interface Piece {
  x: number;
  y: number;
  w: number;
  h: number;
  v?: boolean;
  o: 'h' | 'v'; // orientation
}

interface SliderProps {
  disabled?: boolean;
  onClick?: () => void;
  speech?: string;
}

export const Slider = (props: SliderProps) => {
  const [{ currentPlayer: { team } }] = useCurrentPlayer();
  const isBlueTeam = team === 'blue';
  const setModalVisible = useStoreActions((state: any) => state.app.setModalOpen);
  const [solved, setSolved] = useState(0);
  const [pieces, setPieces] = useState<Piece[]>([
    { x: 0, y: 1, w: 1, h: 2, o: 'v' },
    { x: 0, y: 3, w: 1, h: 3, o: 'v' },
    { x: 2, y: 5, w: 3, h: 1, o: 'h' },
    { x: 1, y: 1, w: 2, h: 1, o: 'h' },
    { x: 2, y: 2, w: 1, h: 2, o: 'v' },
    { x: 1, y: 4, w: 1, h: 2, o: 'v' },
    { x: 5, y: 3, w: 1, h: 3, o: 'v' },
    { x: 3, y: 0, w: 1, h: 3, o: 'v' },
    { x: 0, y: 0, w: 3, h: 1, o: 'h' },
    { x: 1, y: 2, w: 1, h: 2, o: 'v' },
    { x: 4, y: 0, w: 1, h: 2, o: 'v' },
    { x: 4, y: 2, w: 2, h: 1, o: 'h', v: true }
  ]);

  const onSolved = () => {
    setModalVisible(null);
    setTimeout(() => {
      sounds.magic();
      setSolved(1);
      setTimeout(() => setSolved(2), 0);
      setTimeout(() => {
        setSolved(3); // crazy number magic to handle fadein/out
      }, 2000);
    }, 1000);
  };

  const isCollision = (x: number, y: number) => {
    for (const piece of pieces) {
      if (x >= piece.x && x <= piece.x + piece.w - 1 && y >= piece.y && y <= piece.y + piece.h - 1) {
        return true;
      }
    }
    return false;
  };

  const probeUp = (piece: Piece) => {
    for (let y = piece.y - 1; y >= 0; y--) {
      if (isCollision(piece.x, y)) {
        return y + 1;
      }
    }
    return 0;
  };

  const probeDown = (piece: Piece) => {
    for (let y = piece.y + piece.h; y <= GRID[1]; y++) {
      if (isCollision(piece.x, y)) {
        return y - piece.h;
      }
    }
    return GRID[1] - piece.h;
  };

  const probeLeft = (piece: Piece) => {
    for (let x = piece.x - 1; x >= 0; x--) {
      if (isCollision(x, piece.y)) {
        return x + 1;
      }
    }
    return 0;
  };

  const probeRight = (piece: Piece) => {
    for (let x = piece.x + piece.w; x <= GRID[0]; x++) {
      if (isCollision(x, piece.y)) {
        return x - piece.w;
      }
    }
    return GRID[0] - piece.w;
  };

  const togglePiece = (i: number) => {
    sounds.weapon();
    const updatedPieces = [...pieces];
    const piece = updatedPieces[i];
    if (piece.o === 'v') {
      const yUp = probeUp(piece);
      const yDown = probeDown(piece);
      piece.y = piece.y === yUp ? yDown : yUp;
    } else {
      const xLeft = probeLeft(piece);
      const xRight = probeRight(piece);
      piece.x = piece.x === xLeft ? xRight : xLeft;
      if (piece.v && piece.x === 0) {
        piece.x = -1;
        setTimeout(() => onSolved(), 500);
      }
    }
    setPieces(updatedPieces);
  };

  const sliderHints = [
    'This puzzle requires just this part to solve it.',
    'The goal is to get the red piece out of the other side of the puzzle by moving the pieces around to get it through. Completing it will reveal vital information.',
    <>
      If you want to complete the puzzle press the below button and it will autocomplete
      <p><Button type="primary" size="large" onClick={() => onSolved()}>Autocomplete</Button></p>
    </>
  ];

  return (
    <>
      {solved && (
        <GameItem
          disabled={props.disabled}
          onClick={props.onClick}
          iconSrc={sliderChestOpen}
          iconStyle={{
            left: '29.6%',
            top: '70.4%',
            width: '36.7%',
            transition: 'opacity 2s',
            opacity: Math.min(solved - 1, 1)
          }}
          canvas={false}
          name="l5SliderReward"
          description="Is this a photo of the BOMB?"
          hints={sliderOpenHints}
          imageSrc={isBlueTeam ? sliderSolution1 : sliderSolution2}
        />
      )}
      {solved !== 3 && (
        <GameItem
          disabled={props.disabled}
          onClick={props.onClick}
          speech={props.speech}
          iconStyle={{
            left: '37%',
            top: '71%',
            width: '28.3%',
            height: '31%',
            transform: 'rotate(4deg)'
          }}
          canvas={false}
          name="l5SliderChest"
          hints={sliderHints}
          description="Is this a sliding puzzle? A childhood classic!"
        >
          <div className={style.playArea} style={{ backgroundImage: `url(${playArea})` }}>
            <div className={style.grid}>
              {pieces.map((piece, i) => {
                const pieceBackground = (() => {
                  if (piece.v) {
                    return brick1x2Special;
                  }
                  if (piece.w === 1 && piece.h === 2) {
                    return brick2x1;
                  }
                  if (piece.w === 1 && piece.h === 3) {
                    return brick3x1;
                  }
                  if (piece.w === 2 && piece.h === 1) {
                    return brick1x2;
                  }
                  if (piece.w === 3 && piece.h === 1) {
                    return brick1x3;
                  }
                  return brick1x2Special;
                })();

                return (
                  <div
                    className={classnames(style.piece, piece.v ? style.goal : '')}
                    style={{
                      width: `${elementWidth * piece.w}%`,
                      height: `${elementHeight * piece.h}%`,
                      left: `${(elementHeight * piece.x)}%`,
                      top: `${(elementWidth * piece.y)}%`,
                      zIndex: 1,
                      backgroundImage: `url(${pieceBackground})`
                    }}
                    onClick={() => togglePiece(i)}
                  />
                );
              })}
            </div>
          </div>
        </GameItem>
      )}
    </>
  );
};
