import React, { useState } from 'react';
import classnames from 'classnames';

import { OnOffDevice, trueFalseHints, onOffDeviceImages } from './OnOffDevice';
import { useAccessCode, useCurrentPlayer } from '../../state';
import { GameItem, GameLock, sounds } from '../../components';

import keys1 from './img/keys1.png';
import keys2 from './img/keys2.png';
import lock from './img/lock.png';
import lockIcon from './img/lockIcon.png';
import box from './img/box.png';
import chain1 from './img/chain1.png';
import chain2 from './img/chain2.png';
import points1 from './img/points1.png';
import points2 from './img/points2.png';
import gold from './img/gold.png';
import cryptex from './img/cryptex.png';
import cryptexIcon from './img/cryptexIcon.png';
import cryptexOpen from './img/cryptexOpen.png';
import trueNote1 from './img/trueNote1.png';
import trueNote2 from './img/trueNote2.png';
import openBox from './img/openBox.png';

import style from './Level2.module.css';

export const level2Images = [
  '/img/l2Red.jpg',
  '/img/l2Blue.jpg',
  keys1,
  keys2,
  lock,
  lockIcon,
  box,
  chain1,
  chain2,
  points1,
  points2,
  gold,
  cryptex,
  cryptexIcon,
  cryptexOpen,
  trueNote1,
  trueNote2,
  openBox,
  ...onOffDeviceImages
];

const keysLockSolutions = [
  { combination: '5235', correct: true },
  { combination: '0111', correct: false, message: 'Not quite like that' }
];

const keysLockHints = [
  'This lock requires information from the four other puzzles on this level, completing them should give you the information you need to open it.',
  'The symbols show the order to put the numbers in. The dots represent the puzzle which is represented by the dotted grid/piece of paper with numbers, the digital 8 is to represent the true false puzzle, the skull icon represents the cryptex puzzle and the fish the fishing lure puzzle.',
  'The answer is 5235.'
];

const goldHints = [
  'This puzzle requires one element from each teams’ box, the photo from Old Dog’s team and the cryptex from the Ally’s team.',
  'The information you require for the cryptex can be found in the photo.',
  'What is the ship\'s name?',
  'You can open the cryptex by providing Ally’s team with the name of the ship.'
];

const cryptexSolutions = [
  { combination: '4719', correct: true }
  // { combination: '5318', correct: true } // Default MelB
];

const cryptexHints = [
  'This puzzle requires one element from each teams’ box, the photo from Old Dog’s team and the cryptex from Ally’s team.',
  'The information you require for the cryptex can be found in the photo',
  'Use the information from the photo to input 4 letters into the cryptex',
  'The answer is G-O-L-D (3).'
];

const cryptexOpenHints = [
  'The number you see (3) needs to be used as a third digit of the lock'
];

const points1Hints = [
  'This puzzle requires one element from each teams’ box, the dotted grid from Old Dog’s team and the piece of paper with numbers on it from Ally’s team.',
  'Allow Old Dog’s team to guide you in joining up dots, the numbers at the side are an indicator to keep you on track allowing you to work out what number each dot represents.',
  'Connecting the numbers that Old Dog’s team gives you should spell out a number.',
  'The numbers should allow you to form the word F-I-V-E.'
];

const points2Hints = [
  'This puzzle requires one element from each teams’ box, the dotted grid from Old Dog’s team and the piece of paper with numbers on it from Ally’s team.',
  'Use these numbers to guide the Ally’s team.',
  'If Ally’s team uses these numbers on the dotted grid it should spell out a number.',
  'The answer is F-I-V-E.'
];

const keysHints = [
  'This puzzle requires the fishing lures from Ally’s team and the wooden board in the shark jaws from Old Dog’s team.',
  'Cooperate to work out which lures from Ally’s team belong in the slots on Old Dog’s team\'s board to reveal a simple maths problem. Beware that the lures may have the same shape at the top but there are different numbers of hooks on the bottom so find the correct lures to match.',
  'Completing the maths puzzle should be 8+2-5=5.'
];

const transition = {
  transition: 'opacity 2s',
  transitionDelay: '500ms'
};

export const Level3Waiting = () => (
  <>
    <h1>Great work, you’ve managed to get through the second level!</h1>
    <p><img src={openBox} alt="chestopens" style={{ width: '250px' }} /></p>
    <p>The detective opens the box and finds even more items inside. They also notice what looks like a hidden compartment in the floor of the box, complete with a padlock.</p>
    <p>Is there a second bottom to this chest? It looks like we know what our next goal is!</p>
  </>
);

export const Level2 = () => {
  const [, { isCorp }] = useAccessCode();
  const [{ currentPlayer: { team } }, { changeLevel }] = useCurrentPlayer();
  const isBlueTeam = team === 'blue';
  const [lockVisible, setKeysVisible] = useState(true);
  const [lockClickable, setKeysClickable] = useState(true);
  const [cryptexVisible, setCryptexVisible] = useState(true);
  const [openCryptexVisible, setOpenCryptexVisible] = useState(false);

  const onBoxClick = () => {
    sounds.success2();
    if (isCorp()) {
      setTimeout(() => changeLevel('4-waiting'), 500);
    } else {
      setTimeout(() => changeLevel('3-waiting'), 500);
    }
  };

  return (
    <div className={style.level} style={{ backgroundColor: 'transparent' }}>
      <img src={box} className={style.box} alt="Box" />
      <img src={chain1} className={classnames(style.box, lockVisible ? '' : style.hidden)} alt="Key Chain" />
      <img src={chain2} className={classnames(style.box, lockVisible ? '' : style.hidden)} alt="Key Chain" />
      {!lockClickable && (
        <div className={classnames(style.box, style.boxClickArea)} onClick={onBoxClick} />
      )}
      <GameItem
        iconStyle={{ left: '6%', top: '39%', width: '24.5%', height: '23.8%', transform: 'rotate(-5deg)' }}
        imageSrc={isBlueTeam ? points1 : points2}
        hints={isBlueTeam ? points1Hints : points2Hints}
        canvas={true}
        name="l2PoitsNote"
        description={isBlueTeam ? 'What I\'m supposed to do with this?' : 'Is this some sort of a code?'}
        speech={isBlueTeam ? 'stefanLevel2DottedGrid' : 'allyLevel2PaperWithNumbers'}
      />
      <GameItem
        iconStyle={{ left: '5.7%', top: '66%', width: '18.3%', height: '29%', transform: 'rotate(-5deg)' }}
        imageSrc={isBlueTeam ? trueNote1 : trueNote2}
        hints={trueFalseHints}
        canvas={true}
        name="l2TrueNote"
        speech={isBlueTeam ? 'stefanLevel2TorFstatements' : 'allyLevel2TorFstatements'}
        description="Did a child write this?"
      />
      { isBlueTeam ? (
        <GameItem
          iconStyle={{ left: '8.3%', top: '9%', width: '20%', height: '28.5%', transform: 'rotate(-17deg)' }}
          imageSrc={gold}
          canvas={true}
          hints={goldHints}
          name="l2GoldCryptex"
          description="Golden picture, wonder where it was taken?"
          speech="stefanLevel2Photo"
        />
      ) : (
        <GameLock
          type="letters"
          iconSrc={cryptexIcon}
          iconStyle={{ left: '8%', top: '9%', width: '20%', transform: 'rotate(-10deg)', opacity: cryptexVisible ? 1 : 0, ...transition }}
          imageSrc={cryptex}
          initialValue={[6, 6, 6, 6]}
          name="l2CryptexLock"
          description="Is this cryptex?"
          numberContainerStyle={{ top: '21%' }}
          solutions={cryptexSolutions}
          hints={cryptexHints}
          speech="allyLevel2Cryptex"
          onSolved={() => {
            setCryptexVisible(false);
            setTimeout(() => sounds.magic(), 1000);
            setTimeout(() => setOpenCryptexVisible(true), 500);
          }}
        />
      )}
      { !cryptexVisible && (
        <GameItem
          iconSrc={cryptexOpen}
          iconStyle={{ left: '8%', top: '7%', width: '29%', transform: 'rotate(-7deg)', opacity: openCryptexVisible ? 1 : 0, ...transition }}
          imageSrc={cryptexOpen}
          canvas={true}
          hints={cryptexOpenHints}
          name="l2CryptexOpen"
          description="Cryptex is now open!"
        />
      )}
      { lockClickable && (
        <>
          <GameLock
            type="numbers"
            iconSrc={lockIcon}
            iconStyle={{ left: '56.9%', top: '12.9%', width: '20%', transform: 'rotate(5deg)', opacity: lockVisible ? 1 : 0, ...transition }}
            imageSrc={lock}
            name="l2KeysLock"
            description="Where have I seen these shapes before?"
            numberContainerStyle={{ top: '40%' }}
            solutions={keysLockSolutions}
            hints={keysLockHints}
            speech={isBlueTeam ? 'stefanLevel2Padlock' : 'allyLevel2Padlock'}
            onSolved={() => {
              setKeysVisible(false);
              setTimeout(() => sounds.magic(), 1000);
              setTimeout(() => setKeysClickable(false), 2500);
            }}
          />
        </>
      )}
      <GameItem
        iconStyle={isBlueTeam ? (
          { left: '61%', top: '57%', width: '34.8%', height: '38%', transform: 'rotate(0deg)' }
        ) : (
          { left: '62%', top: '59%', width: '36%', height: '37.5%', transform: 'rotate(0deg)' }
        )}
        imageSrc={isBlueTeam ? keys1 : keys2}
        canvas={true}
        name="l2keys1"
        description={isBlueTeam ? 'Looks like I’m missing something here…' : 'Various numbered lures of different shapes and sizes'}
        hints={keysHints}
        speech={isBlueTeam ? 'stefanLevel2NewFishingPuzzle' : 'allyLevel2NewFishingPuzzle'}
      />
      <OnOffDevice />
    </div>
  );
};
