import axios from 'axios';
import { voices } from './voices';

export { voices } from './voices';

// preload audio files
const soundFiles = {
  open: './sounds/door.mp3',
  lockTurn: './sounds/lock1.mp3',
  magic: './sounds/magic.mp3',
  connect: './sounds/connect.mp3',
  success: './sounds/success.mp3',
  success2: './sounds/success2.mp3',
  fanfare: './sounds/fanfare.mp3',
  error: './sounds/error.mp3',
  beep: './sounds/beep.mp3',
  click: './sounds/click.mp3',
  powerUp: './sounds/powerUp.mp3',
  potion: './sounds/potion.mp3',
  weapon: './sounds/weapon.mp3',
  tick: './sounds/tick.mp3',
  tack: './sounds/tack.mp3',
  toe: './sounds/toe.mp3',
  alarm: './sounds/alarm.mp3',
  mystery: './sounds/mystery.mp3',
  mystery2: './sounds/mystery2.mp3',
  mystery3: './sounds/mystery3.mp3',
  wobble: './sounds/wobble.mp3',

  // animal device
  // animals: './sounds/all.mp3',
  animals: './sounds/animals/animals.mp3',
  victims: './sounds/victims.mp3',
  sheep: './sounds/sheep.mp3',
  cock: './sounds/cock.mp3',
  horse: './sounds/horse.mp3',
  cat: './sounds/cat.mp3',
  whale: './sounds/animals/whale.mp3',
  seagulls: './sounds/animals/seagulls.mp3',
  sealions: './sounds/animals/sealions.mp3',
  dolphin: './sounds/animals/dolphin.mp3',

  // waveform device
  blue: './sounds/blue.mp3',
  eight: './sounds/eight.mp3',
  four: './sounds/four.mp3',
  green: './sounds/green.mp3',
  nine: './sounds/nine.mp3',
  plus: './sounds/plus.mp3',
  minus: './sounds/minus.mp3',
  red: './sounds/red.mp3',
  two: './sounds/two.mp3',
  yellow: './sounds/yellow.mp3',
  valve: './sounds/valve.mp3',
  knock: './sounds/knock.mp3',

  // waveform device 2
  a: './sounds/a.mp3',
  b: './sounds/b.mp3',
  c: './sounds/c.mp3',
  d: './sounds/d.mp3',
  e: './sounds/e.mp3',
  g: './sounds/g.mp3',
  o: './sounds/o.mp3',
  p: './sounds/p.mp3',
  r: './sounds/r.mp3',
  t: './sounds/t.mp3',
  u: './sounds/u.mp3',

  // detectives
  chance: './sounds/stefan/5050chance.mp3',
  genius: './sounds/stefan/genius.mp3',
  handleThis: './sounds/stefan/handle-this-one.mp3',
  kid: './sounds/stefan/kid.mp3',
  prettySimple: './sounds/stefan/pretty-simple.mp3',
  whatName: './sounds/stefan/what-kind-of-name-is-that.mp3'
};

Object.entries(voices).forEach(([key, value]) => {
  (soundFiles as any)[key] = value.file;
});

const buffers: { [key: string]: AudioBuffer } = {};

const AudioContext = window.AudioContext || (window as any).webkitAudioContext;
const context = new AudioContext();

export function preloadAudioFiles(onProgress?: (progress: number, completed: boolean) => void) {
  const filesToPreload = Object.values(soundFiles);
  let completed = 0;

  filesToPreload.forEach(async(filename: string) => {
    if (!context) return;
    const res = await axios.get(filename, { responseType: 'arraybuffer' });
    context.decodeAudioData(res.data, (buffer) => {
      buffers[filename] = buffer;
      completed++;
      onProgress && onProgress(completed / filesToPreload.length, completed === filesToPreload.length);
    });
  });
}

preloadAudioFiles(); // make sure audio files will preload

function playSound(filename: string, volume = 1) {
  if (context) {
    // FIXME: shouldn't we keep the source in memory as well?
    const source = context.createBufferSource();
    source.buffer = buffers[filename];
    const gainNode = context.createGain();
    gainNode.gain.value = volume;
    source.connect(gainNode);
    gainNode.connect(context.destination);
    source.start(0);
  } else {
    (new Audio(filename)).play();
  }
}

export const sounds: { [key: string]: (volume?: number) => void } = {};

for (const prop in soundFiles) {
  sounds[prop] = (volume?: number) => playSound((soundFiles as any)[prop], volume || 1);
}
