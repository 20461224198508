import React from 'react';
import { ConnectedIcon, sounds, CartoonButton, GameItem } from '../../../components';
import { useCurrentPlayer } from '../../../state';
import { useSlices } from './state';

import style from './DeviceModal.module.css';
import device from './img/twoButtonDevice.png';

import sliceBlueA from './img/slice1-1.png';
import sliceBlueB from './img/slice1-2.png';
import sliceBlueC from './img/slice2-1.png';
import sliceBlueD from './img/slice2-2.png';
import sliceRedA from './img/slice3-1.png';
import sliceRedB from './img/slice3-2.png';
import sliceRedC from './img/slice4-1.png';
import sliceRedD from './img/slice4-2.png';

import button1On from './img/button1On.png';
import button1Off from './img/button1Off.png';
import button1Hover from './img/button1Hover.png';
import button2On from './img/button2On.png';
import button2Off from './img/button2Off.png';
import button2Hover from './img/button2Hover.png';

export const deviceImages = [
  sliceBlueA,
  sliceBlueB,
  sliceBlueC,
  sliceBlueD,
  sliceRedA,
  sliceRedB,
  sliceRedC,
  sliceRedD,
  button1On,
  button1Off,
  button1Hover,
  button2On,
  button2Off,
  button2Hover
];

const hints = [
  'This device is connected to something else. Pushing the buttons will show you some information. It is not broken, but you only see half of what you need.',
  'By both pushing the button at the same time you should see things more clearly.',
  'You should be able to use the numbers assigned to each shape to open the lock; some simple maths may be required.',
  <>
    The information should give you:<br />
    Diamond = 7<br />
    Square = 3<br />
    Triangle = 4<br />
    Circle = 1
  </>
];

export const DeviceModal = () => {
  const [{ currentPlayer: { team } }] = useCurrentPlayer();
  const isBlueTeam = team === 'blue';
  const [visibleSlices, setVisibleSlices] = useSlices();

  const showBlueSlice = () => setVisibleSlices('blue', true);
  const hideBlueSlice = () => setVisibleSlices('blue', false);
  const showRedSlice = () => setVisibleSlices('red', true);
  const hideRedSlice = () => setVisibleSlices('red', false);

  return (
    <GameItem
      iconStyle={{ left: '69%', top: '20%', width: '18%', height: '32%' }}
      canvas={false}
      name="Multibutton Device"
      description="A strange device, is it broken? It says connected but to what?"
      hints={hints}
      imageSrc={device}
      speech={isBlueTeam ? 'stefanLevel1device' : 'allyLevel1device'}
    >
      <div className={style.deviceContainer}>
        <ConnectedIcon style={{ top: '21.2%', left: '53%' }} />
        <CartoonButton
          src={button1Off}
          hoverSrc={button1Hover}
          activeSrc={button1On}
          onDown={() => { showBlueSlice(); sounds.tick(); }}
          onUp={() => { hideBlueSlice(); sounds.toe(); }}
          style={{ left: '37.1%', top: '418px', width: '106px' }}
        />
        <CartoonButton
          src={button2Off}
          hoverSrc={button2Hover}
          activeSrc={button2On}
          onDown={() => { showRedSlice(); sounds.tick(); }}
          onUp={() => { hideRedSlice(); sounds.toe(); }}
          style={{ left: '50.4%', top: '423px', width: '106px' }}
        />
        <div className={style.sliceContainer}>
          <img
            className={style.slice}
            src={sliceBlueA}
            alt=""
            style={{ visibility: visibleSlices.blueAVisible && team === 'blue' ? 'visible' : 'hidden' }}
          />
          <img
            className={style.slice}
            src={sliceBlueB}
            alt=""
            style={{ visibility: visibleSlices.blueBVisible && team === 'blue' ? 'visible' : 'hidden' }}
          />
          <img
            className={style.slice}
            src={sliceRedA}
            alt=""
            style={{ visibility: visibleSlices.redAVisible && team === 'red' ? 'visible' : 'hidden' }}
          />
          <img
            className={style.slice}
            src={sliceRedB}
            alt=""
            style={{ visibility: visibleSlices.redBVisible && team === 'red' ? 'visible' : 'hidden' }}
          />

          <img
            className={style.slice2}
            src={sliceBlueC}
            alt=""
            style={{ visibility: visibleSlices.blueAVisible && team === 'red' ? 'visible' : 'hidden' }}
          />
          <img
            className={style.slice2}
            src={sliceBlueD}
            alt=""
            style={{ visibility: visibleSlices.blueBVisible && team === 'red' ? 'visible' : 'hidden' }}
          />
          <img
            className={style.slice2}
            src={sliceRedC}
            alt=""
            style={{ visibility: visibleSlices.redAVisible && team === 'blue' ? 'visible' : 'hidden' }}
          />
          <img
            className={style.slice2}
            src={sliceRedD}
            alt=""
            style={{ visibility: visibleSlices.redBVisible && team === 'blue' ? 'visible' : 'hidden' }}
          />
        </div>
      </div>
    </GameItem>
  );
};
