/* eslint no-param-reassign: "off" */
// https://easy-peasy.now.sh/docs/quick-start.html
import { action } from 'easy-peasy';

type ResizeState = {
  width: number;
  height: number;
};

type SetPayload = {
  width: number;
  height: number;
}

export const initialState: ResizeState = {
  height: 0,
  width: 0
};

export const actions = {
  set: action<ResizeState, SetPayload>((state, { height, width }) => {
    state.height = height;
    state.width = width;
  })
};
