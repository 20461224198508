import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';

import { sounds, voices } from '..';

import style from './SpeechBubble.module.css';

import stefan from './img/stefanFace.jpg';
import ally from './img/allyFace.jpg';
import speaker from './img/speaker.jpg';
import hudSpeech from './img/hudSpeech.png';
import hudSpeech2 from './img/hudSpeech2.png';

export const speechBubbleImages = [stefan, ally, speaker];

interface SpeechBubbleProps {
  phrase: string;
  delay: number;
  person: 'stefan' | 'ally' | 'killer';
  darkOverlay?: boolean;
  onClose: () => void;
}

export const SpeechBubble = ({ phrase, delay, person, darkOverlay, onClose }: SpeechBubbleProps) => {
  const [{ visible }, setState] = useState({ visible: darkOverlay });

  useEffect(() => {
    setTimeout(() => setState({ visible: true }), 300);
    setTimeout(() => setState({ visible: false }), delay - 750);
    setTimeout(() => onClose(), delay);
  }, [delay, onClose]);

  const avatar = (() => {
    switch (person) {
      case 'stefan':
        return stefan;
      case 'ally':
        return ally;
      case 'killer':
        return speaker;
      default:
        return ally;
    }
  })();

  const nickname = (() => {
    switch (person) {
      case 'stefan':
        return 'Old Dog';
      case 'ally':
        return 'Ally';
      case 'killer':
        return 'Cryptic Killer';
      default:
        return 'Unknown';
    }
  })();

  const speechBubbleContainer = (
    <div
      className={classnames(style.speechBubbleContainer, style.withDarkOverlay)}
      style={{ opacity: visible ? 1 : 0 }}
      onClick={() => {
        setState({ visible: false });
        onClose();
      }}
    >
      <div className={style.personHud} style={{ backgroundImage: `url(${hudSpeech})` }} />
      <div className={style.avatar}>
        <img src={avatar} alt="Avatar" />
      </div>
      <div className={style.nameContainer}>
        <span>
          {nickname}
        </span>
      </div>
      <div className={style.speechContent} style={{ backgroundImage: `url(${hudSpeech2})` }}>
        {phrase || 'I don\'t know what to say...'}
      </div>
    </div>
  );

  if (darkOverlay) {
    return (
      <div className={style.darkOverlay} style={{ opacity: visible ? 1 : 0 }}>
        {speechBubbleContainer}
      </div>
    );
  }
  return speechBubbleContainer;
};

const playedSounds: string[] = [];

interface SpeechOptions {
  force?: boolean;
  darkOverlay?: boolean;
}

export const speech = {
  say: (phrase: string, options?: SpeechOptions) => {
    const { force, darkOverlay } = options || {};
    if (playedSounds.indexOf(phrase) !== -1 && !force) {
      return;
    }
    if (!force) {
      playedSounds.push(phrase);
    }

    setTimeout(() => {
      const content = (voices as any)[phrase as any];
      if (!content) {
        console.error('Phrase not found', phrase);
        return;
      }

      const div = document.createElement('div');
      document.body.appendChild(div);
      ReactDOM.render(<SpeechBubble
        phrase={content.text}
        delay={content.delay}
        person={content.person}
        darkOverlay={darkOverlay}
        onClose={() => ReactDOM.unmountComponentAtNode(div)}
      />, div);

      setTimeout(() => {
        try {
          (sounds as any)[phrase](3);
        } catch {
          console.error('Audio phrase not found');
        }
      }, 1);
    }, 10);
  }
};
