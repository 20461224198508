/* eslint no-param-reassign: "off" */
// https://easy-peasy.now.sh/docs/quick-start.html
import { action } from 'easy-peasy';

type PuzzleState = {
  [key: string]: {
    x: number;
    y: number;
  };
};

type SetPayload = {
  key: string;
  x: number;
  y: number;
}

export const initialState: PuzzleState = {};

export const actions = {
  set: action<PuzzleState, SetPayload>((state, { key, x, y }) => {
    state[key] = { x, y };
  })
};
