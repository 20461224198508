import { db, useFirestoreQuery } from '../../../firebase';
import { useAccessCode } from '../../../state';

export type Buttons = [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean];

interface ButtonPayload {
  key: number;
  value: boolean;
}

export const initialState: Buttons = [false, false, false, false, false, false, false, false];

type OnOffButtonsResponse = [Buttons, (button: ButtonPayload) => void]

export function useOnOffButtons(): OnOffButtonsResponse {
  const [{ code }] = useAccessCode();
  const { data } = useFirestoreQuery<ButtonPayload[]>(
    db.collection('game')
      .doc(code)
      .collection('onOffDevice')
  );

  const buttons = initialState;
  data?.forEach(({ key, value }) => buttons[key] = value);

  function setButtons({ key, value }: ButtonPayload) {
    db.collection('game')
      .doc(code)
      .collection('onOffDevice')
      .doc(`${key}`)
      .set({ key, value });
  }

  return [buttons, setButtons];
}
