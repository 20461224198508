import React, { FunctionComponent } from 'react';
import classnames from 'classnames';

import style from './MagicFrame.module.css';

import frameTop from './assets/magicFrameTop.png';
import frameBottom from './assets/magicFrameBottom.png';
import frameMiddle from './assets/magicFrameMiddle.png';

interface MagicFrameProps {
  style?: React.CSSProperties;
  className?: string;
  contentClassName?: string;
}

export const MagicFrame: FunctionComponent<MagicFrameProps> = props => (
  <div style={props.style} className={classnames(props.className, style.frame)}>
    <div className={style.frameTop} style={{ backgroundImage: `url(${frameTop})` }} />
    <div className={style.frameBottom} style={{ backgroundImage: `url(${frameBottom})` }} />
    <div className={style.frameMiddle} style={{ backgroundImage: `url(${frameMiddle})` }} />
    <div className={classnames(style.content, props.contentClassName)}>
      {props.children}
    </div>
  </div>
);
