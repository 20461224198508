import React from 'react';

import style from './CloseIcon.module.css';

export const CloseIcon = () => (
  <div className={style.container}>
    <span className={style.x}>X</span>
    <span className={style.close}>Close</span>
  </div>
);
