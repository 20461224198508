import React from 'react';

import { LoadingOutlined } from '@ant-design/icons';

import style from './Loading.module.css';

export const Loading = () => (
  <div className={style.page}>
    <LoadingOutlined />
  </div>
);
