import React from 'react';
import { GameItem, sounds } from '../../../components';
import { useCurrentPlayer } from '../../../state';
import { useOnOffButtons } from './onOffState';

import device from './img/device.png';
import on from './img/on.png';
import off from './img/off.png';
import screenB from './img/b.png';
import screenBL from './img/bl.png';
import screenBR from './img/br.png';
import screenM from './img/m.png';
import screenT from './img/t.png';
import screenTL from './img/tl.png';
import screenTR from './img/tr.png';
import screenDot from './img/dot.png';

import style from './OnOffDevice.module.css';

export const onOffDeviceImages = [
  device,
  on,
  off,
  screenB,
  screenBL,
  screenBR,
  screenM,
  screenT,
  screenTL,
  screenTR,
  screenDot
];

export const trueFalseHints = [
  'This puzzle requires both the pieces of handwritten lined paper and the true and false device from each box.',
  'Look closely to see which number of question you are answering.',
  'You may need some cooperation to solve this puzzle and if stuck maybe search the internet for an answer.',
  'Old Dog’s team\'s answers on the panel should be T,F,F,T and Ally’s team\'s answers should be T,T,T,F, this should display the number 2 on both panels.'
];

interface OnOffButtonProps {
  idx: number;
  isOn: boolean;
  style?: React.CSSProperties;
  onClick: (idx: number) => void;
}

const OnOffButton = (props: OnOffButtonProps) => (
  <>
    <img
      src={props.isOn ? on : off}
      className={style.onOffButton}
      alt={props.isOn ? 'on' : 'off'}
      style={{ ...props.style }}
      onClick={() => props.onClick(props.idx)}
    />
    <span className={style.idx} style={{ ...props.style }}>
      {props.idx + 1}.
    </span>
  </>
);

interface ScreenProps {
  buttons: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean];
}

const Screen = ({ buttons }: ScreenProps) => (
  <div className={style.screen}>
    <img src={screenB} alt="screenB" className={style.screen} style={{ opacity: +buttons[0] }} />
    <img src={screenBL} alt="screenBL" className={style.screen} style={{ opacity: +buttons[1] }} />
    <img src={screenBR} alt="screenBR" className={style.screen} style={{ opacity: +buttons[2] }} />
    <img src={screenM} alt="screenM" className={style.screen} style={{ opacity: +buttons[3] }} />
    <img src={screenT} alt="screenT" className={style.screen} style={{ opacity: +buttons[4] }} />
    <img src={screenTL} alt="screenTL" className={style.screen} style={{ opacity: +buttons[5] }} />
    <img src={screenTR} alt="screenTR" className={style.screen} style={{ opacity: +buttons[6] }} />
    <img src={screenDot} alt="screenDot" className={style.screen} style={{ opacity: +buttons[7] }} />
  </div>
);

export const OnOffDevice = () => {
  const [{ currentPlayer: { team } }] = useCurrentPlayer();
  const isBT = team === 'blue';
  const [buttons, setButton] = useOnOffButtons();

  const toggleButton = (idx: number) => {
    setButton({ key: idx, value: !buttons[idx] });
    sounds.click();
  };

  return (
    <GameItem
      iconStyle={{ left: '27%', top: '52%', width: '33%', height: '46%', transform: 'rotate(9.3deg)' }}
      canvas={false}
      name="l2OnOffDevice"
      description="A device with true or false switches but what is it referring to?"
      destroyOnClose={false}
      hints={trueFalseHints}
      speech={isBT ? 'stefanLevel2Switches' : 'allyLevel2Switches'}
    >
      <img src={device} className={style.device} alt="On Off Device" />
      <Screen buttons={buttons} />
      <OnOffButton idx={isBT ? 1 : 0} onClick={toggleButton} isOn={buttons[isBT ? 1 : 0]} style={{ left: '32.8%' }} />
      <OnOffButton idx={isBT ? 2 : 3} onClick={toggleButton} isOn={buttons[isBT ? 2 : 3]} style={{ left: '42.1%' }} />
      <OnOffButton idx={isBT ? 5 : 4} onClick={toggleButton} isOn={buttons[isBT ? 5 : 4]} style={{ left: '51.6%' }} />
      <OnOffButton idx={isBT ? 6 : 7} onClick={toggleButton} isOn={buttons[isBT ? 6 : 7]} style={{ left: '61.3%' }} />
    </GameItem>
  );
};
