import { db, useFirestoreQuery } from '../../../firebase';
import { useAccessCode } from '../../../state';
import { sounds } from '../../../components';

// const SOLUTION = [
//   ['right', 3],
//   ['down', 3],
//   ['right', 1],
//   ['up', 7],
//   ['left', 1],
//   ['down', 3],
//   ['left', 2],
//   ['up', 3],
//   ['left', 1],
//   ['down', 4]
// ];

const SOLUTION = [
  ['right', 1],
  ['right', 1],
  ['right', 1],
  ['down', 1],
  ['down', 1],
  ['down', 1],
  ['right', 1],
  ['up', 1],
  ['up', 1],
  ['up', 1],
  ['up', 1],
  ['up', 1],
  ['up', 1],
  ['up', 1],
  ['left', 1],
  ['down', 1],
  ['down', 1],
  ['down', 1],
  ['left', 1],
  ['left', 1],
  ['up', 1],
  ['up', 1],
  ['up', 1],
  ['left', 1],
  ['down', 1],
  ['down', 1],
  ['down', 1],
  ['down', 1]
];

type Direction = 'down' | 'left' | 'right' | 'up';
type Line = [Direction, number];

type LabyrinthState = {
  success: boolean;
  input: Line[];
};

export const initialState: LabyrinthState = {
  success: false,
  input: []
};

type LabyrinthResult = [LabyrinthState, (direction: Direction) => void];

export function useLabyrinth(): LabyrinthResult {
  const [{ code }] = useAccessCode();
  const { data } = useFirestoreQuery<{ input: string; success: boolean }>(
    db.collection('game')
      .doc(code)
      .collection('labyrinth')
      .doc('state')
  );

  const input: Line[] = JSON.parse(data?.input || JSON.stringify([]));

  function onMove(direction: Direction) {
    if (data?.success) return;

    let lines = [...input];

    if (lines.length === SOLUTION.length) {
      sounds.beep();
      return;
    }

    const [correctDirection, distance] = SOLUTION[lines.length];

    if (correctDirection === direction) {
      lines.push([direction, distance as number]);
      if (lines.length === SOLUTION.length) {
        sounds.success();
      } else {
        sounds.beep();
      }
    } else {
      sounds.error();
      lines = [];
    }

    const success = lines.length === SOLUTION.length;

    db.collection('game')
      .doc(code)
      .collection('labyrinth')
      .doc('state')
      .set({
        input: JSON.stringify(lines),
        success
      });
  }

  return [{ success: !!data?.success, input }, onMove];
}
