import { useEffect } from 'react';
import firebase from 'firebase/app';
import { randomString } from '../utils/randomString';
import { db, database, useFirestoreQuery } from '../firebase';
import { useAccessCode } from './accessCode';

const playerId = localStorage.playerId || randomString(16);
localStorage.playerId = playerId;

const userStatusDatabaseRef = database.ref(`/playerStatus/${playerId}`);

let presenceListenerAdded = false;

export interface Player {
  id: string;
  name: string;
  // blue or red
  team: string;
  state: string; // online or offline
  // determines if the player already joined the game. If false, player will be asked to enter the name and choose a team
  // most used when a player is switching browser
  started: boolean;
  level: string;
  createdAt?: string;
  updatedAt?: string;
}

type UseCurrentPlayerResult = [
  {
    currentPlayer: Player;
    loading: boolean;
  }, {
    setCurrentPlayer: (player: Partial<Player>) => void;
    changeLevel: (level: string) => void;

    /**
     * Log user action. Example:
     * logAction('open', 'l1Chest')
     */
    logAction: (action: string, item: string, meta?: any) => void;
  }
];

export function useCurrentPlayer(): UseCurrentPlayerResult {
  const [{ code }] = useAccessCode();

  const { data, status } = useFirestoreQuery<Player>(
    db.collection('game')
      .doc(code || '-')
      .collection('players')
      .doc(playerId)
  );

  // handle presence
  useEffect(() => {
    if (!code || presenceListenerAdded) {
      return;
    }
    presenceListenerAdded = true;

    database.ref('.info/connected').on('value', (snapshot) => {
      if (!snapshot.val()) {
        return;
      }

      userStatusDatabaseRef.onDisconnect()
        .set({
          code,
          state: 'offline',
          lastChanged: firebase.database.ServerValue.TIMESTAMP
        })
        .then(() => userStatusDatabaseRef.set({
          code,
          state: 'online',
          lastChanged: firebase.database.ServerValue.TIMESTAMP
        }));
    });
  }, [code]);

  const player: Player = {
    id: playerId,
    name: '',
    team: '',
    level: '',
    started: false,
    state: 'online',
    ...(data || {})
  };

  function setCurrentPlayer(p: Partial<Player>) {
    if (!code) return;

    db.collection('game')
      .doc(code)
      .collection('players')
      .doc(playerId)
      .set({
        ...p,
        createdAt: player.createdAt || firebase.firestore.Timestamp.fromDate(new Date()),
        updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
        userAgent: window.navigator.userAgent
      }, { merge: true });
  }

  function changeLevel(level: string) {
    setCurrentPlayer({ level });
    setTimeout(() => window.scrollTo(0, 0), 1);
  }

  function logAction(action: string, item: string, meta: any = {}) {
    if (!code || !playerId) return;

    try {
      db.collection('game')
        .doc(code)
        .collection('players')
        .doc(playerId)
        .collection('actions')
        .add({
          action,
          item,
          meta,
          at: firebase.firestore.Timestamp.fromDate(new Date())
        });
    } catch (err) {
      console.error(err);
    }
  }

  return [{ currentPlayer: player, loading: status === 'loading' }, { setCurrentPlayer, changeLevel, logAction }];
}

export function usePlayers() {
  const [{ code }] = useAccessCode();
  return useFirestoreQuery<Player[]>(
    db.collection('game')
      .doc(code)
      .collection('players')
      .where('state', '==', 'online')
  );
}
