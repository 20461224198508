import React, { FunctionComponent, useState } from 'react';
import { Card, Steps, Button } from 'antd';

import style from './Hints.module.css';
import { useGame } from '../../state';

const { Step } = Steps;

interface HintsProps {
  itemName: string;
  hints: React.ReactNode[];
}

export const Hints: FunctionComponent<HintsProps> = ({ hints, itemName }) => {
  const [current, setCurrent] = useState(-1);
  const [completed, setCompleted] = useState(-1);
  const [, { logHint }] = useGame();

  function setPage(page: number) {
    setCurrent(page);
    setCompleted(Math.max(page, completed));
    logHint(itemName, page + 1);
  }

  if (hints.length === 0) {
    return (
      <Card className={style.hints}>
        <p>Sorry, this puzzle has no hints... yet!</p>
      </Card>
    );
  }

  return (
    <Card className={style.hints}>
      <p>You can choose to take as many hints as you wish, the only thing negatively impacted is your pride...</p>

      <Button
        size="large"
        type="primary"
        disabled={current > hints.length - 2}
        onClick={() => setPage(current + 1)}
      >
        {current === -1 ? 'I don\'t care, show me a hint!' : 'Show me next hint'}
      </Button>

      <Steps
        className={style.steps}
        type="navigation"
        current={current}
        onChange={setPage}
      >
        {hints.map((hint, i) => (
          <Step status={completed >= i ? 'finish' : 'wait'} title={i + 1 < hints.length ? `Hint ${i + 1}` : 'Solution'} />
        ))}
      </Steps>
      <div className={style.hintContent}>
        {current > -1 && current < hints.length ? hints[current] : ''}
      </div>
    </Card>
  );
};
