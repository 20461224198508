import React, { memo } from 'react';
import { BeakerImage } from './Beaker';

const styles = {
  display: 'inline-block',
  transform: 'rotate(7deg)',
  WebkitTransform: 'rotate(7deg)',
  width: '120px'
};

export interface BeakerDragPreviewProps {
  name: string;
  isBlueTeam: boolean;
}

export const BeakerDragPreview: React.FC<BeakerDragPreviewProps> = memo(
  ({ name, isBlueTeam }) => (
    <div style={styles}>
      <BeakerImage name={name} isBlueTeam={isBlueTeam} />
    </div>
  )
);
