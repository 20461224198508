/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useState } from 'react';
import { Input, Button, Form, Divider } from 'antd';
import urlencode from 'urlencode';
import axios from 'axios';
import { validate } from 'email-validator';
import { TwitterOutlined, FacebookOutlined } from '@ant-design/icons';
import { useGame } from '../../state';
import { MagicFrame } from '../../components';

import style from './Congratulations.module.css';

import success1 from './img/giphy-1.gif';
import success2 from './img/giphy-2.gif';
import success4 from './img/giphy-4.gif';
import success5 from './img/giphy-5.gif';
import success6 from './img/giphy-6.gif';
import success7 from './img/giphy-7.gif';
// import superheroes from './img/superheroes.png';
import parallelLab from './img/parallel-lab.jpg';

const NEWSLETTER_URL = 'https://us-central1-eleven-puzzles-website.cloudfunctions.net/newsletter';

/**
 * @param {number} time in seconds * 1000
 */
export function formatTime(time: number) {
  const timer = Math.max(Math.floor(time / 1000), 0);

  // timer is in seconds
  const s = timer % 60;
  const m = ((timer - s) / 60) % 60;
  const h = (timer - s - m * 60) / 3600;

  return `${h}:${m < 10 ? '0' : ''}${m}:${s < 10 ? '0' : ''}${s}`;
}

export const Congratulations = () => {
  const [{ data }] = useGame();

  // newsletter
  const [email, setEmail] = useState('');
  const [cc0f56a6940e30d95a3a1a7a0_2827db7fbc, setCC] = useState('');
  const [subscribing, setSubscribing] = useState(false);
  const [error, setError] = useState('');
  const [subscribeSuccess, setSubscribeSuccess] = useState(false);

  const totalTime = data?.totalTime;
  const totalTimeText = totalTime ? formatTime(totalTime) : '-';

  const onFormSubmit = useCallback(async() => {
    try {
      setError('');
      setSubscribing(true);

      if (!validate(email)) {
        setError('Please provide a valid e-mail');
        setSubscribing(false);
        return;
      }

      const response = await axios.post(NEWSLETTER_URL, {
        b_cc0f56a6940e30d95a3a1a7a0_2827db7fbc: cc0f56a6940e30d95a3a1a7a0_2827db7fbc,
        email,
        tags: ['game', 'unboxing']
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.data.status === 'ok') {
        setSubscribing(false);
        setSubscribeSuccess(true);
      } else {
        setError('Something went wrong, please try again later');
      }
      setSubscribing(false);
    } catch (err) {
      setSubscribing(false);
      setError('Something went wrong, please try again later');
      console.error(err);
    }
  }, [email, cc0f56a6940e30d95a3a1a7a0_2827db7fbc]);

  return (
    <MagicFrame className={style.congratulations} contentClassName={style.content}>
      <h1>Congratulations!!!</h1>
      <h2>You’ve saved the lives of these two detectives and proved you’re the best at what you do!</h2>
      <div className={style.gifs}>
        <img src={success1} alt={success1} className={style.gif} />
        <img src={success2} alt={success2} className={style.gif} />
        <img src={success4} alt={success4} className={style.gif} />
        <img src={success5} alt={success5} className={style.gif} />
        <img src={success6} alt={success6} className={style.gif} />
        <img src={success7} alt={success7} className={style.gif} />
      </div>
      <p>The detectives thank you as they wipe the sweat from their brows, that was an intense situation they didn’t expect to see the other side of. The joy is short lived however as they soon realise they have survived but the cryptic killer is also still on the loose and will likely act again if they haven’t already. However, for the first time in the cases history the detectives have a lot of evidence to study and hope that they can find some evidence to help point them towards the identity of the killer and get them one step closer to catching them!</p>

      <Divider className={style.divider}>Share your success!</Divider>
      <p>You helped the detectives escape the Cryptic Killer's clutches in:</p>
      <h1>{totalTimeText}</h1>

      <div className={style.socialMediaButtons}>
        <Button
          className={style.shareButton}
          href={`http://twitter.com/share?text=${urlencode(`We just escaped from the Online Escape Room "Unboxing the mind of a Cryptic Killer" in ${totalTimeText}!`)}&url=https://elevenpuzzles.com&hashtags=elevenpuzzles`}
          type="link"
          size="large"
          target="_blank"
        >
          Share on <TwitterOutlined />
        </Button>
        <Button
          className={style.shareButton}
          href={`https://www.facebook.com/sharer/sharer.php?u=https://elevenpuzzles.com&t=TITLE&display=page&hashtag=${urlencode('#elevenpuzzles')}`}
          type="link"
          size="large"
          target="_blank"
        >
          Share on <FacebookOutlined />
        </Button>
      </div>

      <Divider className={style.divider}>Next game</Divider>
      <a href="https://elevenpuzzles.com/games/parallel-lab" target="_blank"><img src={parallelLab} alt="Parallel Lab" className={style.parallelLab} /></a>
      <p>Our next game called Parallel Lab is coming out soon. Subscribe to our newsletter to get notified when it's out!</p>

      {subscribeSuccess ? (
        <p>Thank you for subscribing!</p>
      ) : (
        <form
          action="https://elevenpuzzles.us7.list-manage.com/subscribe/post?u=cc0f56a6940e30d95a3a1a7a0&amp;id=2827db7fbc"
          method="post"
          target="_blank"
          onSubmit={(ev) => {
            ev.preventDefault();
            onFormSubmit();
          }}
        >
          <Form.Item
            name="EMAIL"
            className={style.nameInput}
          >
            {error ? <div className={style.errorMessage}>{error}</div> : <></>}
            <Input
              style={{ textAlign: 'center' }}
              name="EMAIL"
              autoComplete="off"
              type="email"
              placeholder="Please type in your e-mail here"
              onChange={ev => {
                setError('');
                setEmail(ev.target.value);
              }}
              value={email}
              disabled={subscribing}
            />
            <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
              <input type="text" name="b_cc0f56a6940e30d95a3a1a7a0_2827db7fbc" tabIndex={-1} value={cc0f56a6940e30d95a3a1a7a0_2827db7fbc} onChange={ev => setCC(ev.target.value)} />
            </div>
          </Form.Item>

          <Button type="primary" size="large" htmlType="submit" disabled={subscribing}>{subscribing ? 'Subscribing...' : 'Sign up'}</Button>
        </form>
      )}
    </MagicFrame>
  );
};
