import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button } from 'antd';
import classnames from 'classnames';
import 'animate.css';

import { useCurrentPlayer } from '../../../state';
import { useWaveform } from './state';
import { GameItem, sounds } from '../../../components';

import style from './Waveform.module.css';

import buttonPlay from './img/buttonPlay.png';
import closed from './img/closed.png';
import deviceClosedBlue from './img/deviceClosedBlue.png';
import deviceClosedRed from './img/deviceClosedRed.png';
import deviceOpenBlue from './img/deviceOpenBlue.png';
import deviceOpenRed from './img/deviceOpenRed.png';

import buttonUnblocked from './img/buttonUnblocked.png';
import buttonUnblockedActive from './img/buttonUnblockedActive.png';

// generated in https://convert.ing-now.com/generate-a-waveform-image-from-an-audio-file/
import a from './img/waves/a.png';
import b from './img/waves/b.png';
import c from './img/waves/c.png';
import d from './img/waves/d.png';
import e from './img/waves/e.png';
import g from './img/waves/g.png';
import o from './img/waves/o.png';
import p from './img/waves/p.png';
import r from './img/waves/r.png';
import t from './img/waves/t.png';
import u from './img/waves/u.png';

import open from './img/open.png';
import pie from './img/pie.png';
import valve from './img/valve.png';
import knob from './img/knob.png';
import check from './img/buttonCheck.png';

export const waveformImages = [
  buttonPlay,
  closed,
  deviceClosedBlue,
  deviceClosedRed,
  deviceOpenBlue,
  deviceOpenRed,
  buttonUnblocked,
  buttonUnblockedActive,
  a,
  b,
  c,
  d,
  e,
  g,
  o,
  p,
  r,
  t,
  u,
  open,
  pie,
  valve,
  knob,
  check
];

const hintsBlue = [
  'The valves you have on screen are doing something, maybe someone else can see what they are doing and guide you?',
  'The other teams box will open once you have turned the valves to the correct position, the other team can assist you and open your box!',
  'In order to open the windows that are shut above the sound samples you will need to work together? Notice that both of your devices are connected?',
  'Match the sound samples to the one in the window, opened by pressing the button at the same time, this should provide some useful information!',
  'If you match up the sounds samples yours should say C-U-T, the other team should also have three letters pointing you in the right direction.'
];

const hintsRed = [
  'There isn’t much you can do here yourself but perhaps you can guide someone else to do something? Notice the mechanism round the edge of your box?',
  'Once the other team has the locking mechanism round the edge of the box aligned your box will open. The switch in your box will open the other teams box.',
  'In order to open the closed windows that are shut above the sound samples you will need to work together? Notice that both of your devices are connected?',
  'Match the sound samples to the one in the window, opened by pressing the button at the same time, this should provide some useful information!',
  'If you match up the sounds samples yours should say R-E-D, the other team should also have three letters pointing you in the right direction.'
];

const gameSounds = {
  a, b, c, d, e, g, o, p, r, t, u
};

function playSound(sound: string) {
  (sounds as any)[sound]();
}

interface WaveformWindowProps {
  idx: number;
  selected: string;
  isBlueTeam: boolean;
  playing: boolean;
  solved: boolean;
  onClick: (nextSelected: string) => void;
}

const WaveformWindow = ({ selected, onClick, idx, playing, solved }: WaveformWindowProps) => {
  const images: any = gameSounds;
  const keys = Object.keys(gameSounds);
  const selectedImage = images[selected as any] || images[keys[0] as any];
  const nextSelected = keys[(keys.indexOf(selected || keys[0]) + 1) % 11];

  return (
    <div
      className={style.waveformImageContainer}
      style={{ left: `${21.8 + idx * 14.9}%` }}
    >
      <div
        className={classnames(style.waveformImage, solved && style.waveformImageSolved, playing && style.waveformImagePlaying)}
        onClick={() => onClick(nextSelected)}
      >
        <img src={selectedImage} alt="Waveform" />
      </div>
    </div>
  );
};

interface PeekWindowProps {
  isBlueTeam: boolean;
  idx: number;
  leftOpen: boolean;
  rightOpen: boolean;
  onDown: () => void;
  onUp: () => void;
}

const PeekWindow = ({ isBlueTeam, idx, leftOpen, rightOpen, onDown, onUp }: PeekWindowProps) => {
  const combination = isBlueTeam ? ['c', 'u', 't'] : ['r', 'e', 'd'];
  const solutionSrc = (gameSounds as any)[combination[idx]];

  let left = '0%';
  let right = '0%';
  if (rightOpen && leftOpen) {
    left = '-50%';
    right = '-50%';
  } else if (leftOpen) {
    left = '-5%';
  } else if (rightOpen) {
    right = '-5%';
  }

  return (
    <div className={style.peek} style={{ left: `${22.8 + idx * 14.65}%` }}>
      <div className={style.peekContainer}>
        <div className={style.doorLeft} style={{ left }} />
        <div className={style.doorRight} style={{ right }} />
        <img
          src={solutionSrc}
          alt={`Combination ${idx}`}
          className={style.solution}
        />
      </div>
      <Button
        style={{ backgroundImage: `url(${check})` }}
        className={style.solutionButton}
        onMouseDown={onDown}
        onMouseUp={onUp}
        onMouseLeave={onUp}
        onTouchStart={onDown}
        onTouchCancel={onUp}
        onTouchEnd={onUp}
      >{' '}
      </Button>
    </div>
  );
};

interface ValvesProps {
  isBlueTeam: boolean;
  onValveClick: (valve: 1 | 2 | 3 | 4) => void;
  r1: number;
  r2: number;
  r3: number;
  r4: number;
}

const Valves = ({ isBlueTeam, onValveClick, r1, r2, r3, r4 }: ValvesProps) => {
  if (isBlueTeam) {
    return (
      <>
        <img src={valve} alt="valve1" className={style.valve1} style={{ transform: `rotate(${r1 * 90}deg)` }} onClick={() => onValveClick(1)} />
        <img src={valve} alt="valve2" className={style.valve2} style={{ transform: `rotate(${r2 * 90}deg)` }} onClick={() => onValveClick(2)} />
        <img src={valve} alt="valve3" className={style.valve3} style={{ transform: `rotate(${r3 * 90}deg)` }} onClick={() => onValveClick(3)} />
        <img src={valve} alt="valve4" className={style.valve4} style={{ transform: `rotate(${r4 * 90}deg)` }} onClick={() => onValveClick(4)} />
      </>
    );
  }

  return (
    <>
      <img src={pie} alt="pie1" className={style.pie1} style={{ transform: `rotate(${r1 * 90}deg)` }} />
      <img src={pie} alt="pie2" className={style.pie2} style={{ transform: `rotate(${r2 * 90}deg)` }} />
      <img src={pie} alt="pie3" className={style.pie3} style={{ transform: `rotate(${r3 * 90}deg)` }} />
      <img src={pie} alt="pie4" className={style.pie4} style={{ transform: `rotate(${r4 * 90}deg)` }} />
    </>
  );
};

interface InsideProps {
  isBlueTeam: boolean;
  playCombination: () => void;
  selectedWaveform1: string;
  selectedWaveform2: string;
  selectedWaveform3: string;
  setSelectedWaveform: any;
  solved: boolean;
  playing0: boolean;
  playing1: boolean;
  playing2: boolean;
  dL0: boolean;
  dL1: boolean;
  dL2: boolean;
  dR0: boolean;
  dR1: boolean;
  dR2: boolean;
  setDoorOpen: (door: 'L0' | 'L1' | 'L2' | 'R0' | 'R1' | 'R2', isOpen: boolean) => void;
}

const Inside = (props: InsideProps) => (
  <>
    <WaveformWindow
      idx={0}
      isBlueTeam={props.isBlueTeam}
      selected={props.selectedWaveform1}
      playing={props.playing0}
      solved={props.solved}
      onClick={(selected) => {
        if (props.solved) {
          sounds.knock();
        } else {
          props.setSelectedWaveform([selected, props.selectedWaveform2, props.selectedWaveform3]);
          sounds.tick();
        }
      }}
    />
    <WaveformWindow
      idx={1}
      isBlueTeam={props.isBlueTeam}
      selected={props.selectedWaveform2}
      playing={props.playing1}
      solved={props.solved}
      onClick={(selected) => {
        if (props.solved) {
          sounds.knock();
        } else {
          props.setSelectedWaveform([props.selectedWaveform1, selected, props.selectedWaveform3]);
          sounds.tick();
        }
      }}
    />
    <WaveformWindow
      idx={2}
      isBlueTeam={props.isBlueTeam}
      selected={props.selectedWaveform3}
      playing={props.playing2}
      solved={props.solved}
      onClick={(selected) => {
        if (props.solved) {
          sounds.knock();
        } else {
          props.setSelectedWaveform([props.selectedWaveform1, props.selectedWaveform2, selected]);
          sounds.tick();
        }
      }}
    />
    <PeekWindow
      key="peek1"
      isBlueTeam={props.isBlueTeam}
      idx={0}
      leftOpen={props.dL0}
      rightOpen={props.dR0}
      onDown={() => { sounds.toe(); props.setDoorOpen(props.isBlueTeam ? 'L0' : 'R0', true); }}
      onUp={() => props.setDoorOpen(props.isBlueTeam ? 'L0' : 'R0', false)}
    />
    <PeekWindow
      key="peek2"
      isBlueTeam={props.isBlueTeam}
      idx={1}
      leftOpen={props.dL1}
      rightOpen={props.dR1}
      onDown={() => { sounds.toe(); props.setDoorOpen(props.isBlueTeam ? 'L1' : 'R1', true); }}
      onUp={() => props.setDoorOpen(props.isBlueTeam ? 'L1' : 'R1', false)}
    />
    <PeekWindow
      key="peek3"
      isBlueTeam={props.isBlueTeam}
      idx={2}
      leftOpen={props.dL2}
      rightOpen={props.dR2}
      onDown={() => { sounds.toe(); props.setDoorOpen(props.isBlueTeam ? 'L2' : 'R2', true); }}
      onUp={() => props.setDoorOpen(props.isBlueTeam ? 'L2' : 'R2', false)}
    />
    <img
      className={style.playButton2}
      onClick={() => props.playCombination()}
      src={buttonPlay}
      alt="buttonPlay"
    />
  </>
);

interface WaveformProps {
  attention?: boolean;
}

export const Waveform = ({ attention }: WaveformProps) => {
  const [{ currentPlayer: { team } }] = useCurrentPlayer();
  const [{ deviceEnter, deviceActive }, setDeviceState] = useState({
    deviceEnter: false,
    deviceActive: false
  });
  const isBlueTeam = team === 'blue';
  const [
    { visible, loading, r1, r2, r3, r4, redOpen, blueOpen, blueReadyToOpen, dL0, dL1, dL2, dR0, dR1, dR2 },
    { incrementRotation, openBlueDevice, setBlueReadyToOpen, openRedDevice, setDoorOpen, setDeviceData, setWaveformOpened }
  ] = useWaveform();
  const [[playing0, playing1, playing2], setPlaying] = useState([false, false, false]);
  const [timeoutHandle, setTimeoutHandle] = useState<any>();
  const soundKeys = Object.keys(gameSounds);
  const [[selectedWaveform1, selectedWaveform2, selectedWaveform3], setSelectedWaveform] = useState([soundKeys[7], soundKeys[3], soundKeys[4]]);
  const isOpen = isBlueTeam ? blueOpen : redOpen;
  const deviceClosed = isBlueTeam ? deviceClosedBlue : deviceClosedRed;
  const deviceOpen = isBlueTeam ? deviceOpenBlue : deviceOpenRed;
  const solved = `${selectedWaveform1}${selectedWaveform2}${selectedWaveform3}` === (isBlueTeam ? 'cut' : 'red');
  const pieSolved = r1 % 4 === 2 && r2 % 4 === 1 && r3 % 4 === 3 && r4 % 4 === 0;

  const valveLoadingRef = useRef(loading);
  useEffect(() => {
    const wasLoading = valveLoadingRef.current;
    valveLoadingRef.current = loading;
    if (!(r1 === 0 && r2 === 0 && r3 === 0 && r4 === 0) && !wasLoading) {
      sounds.valve();
    }
  }, [r1, r2, r3, r4, loading]);

  const openLoadingRef = useRef(loading);
  useEffect(() => {
    const wasLoading = openLoadingRef.current;
    openLoadingRef.current = loading;
    if (!isBlueTeam && redOpen && !wasLoading) {
      sounds.open();
    }
  }, [redOpen, isBlueTeam, loading]);

  const deviceLoadingRef = useRef(loading);
  useEffect(() => {
    deviceLoadingRef.current = loading;
    if (loading) return;
    if (visible && !deviceEnter) {
      setDeviceState(state => ({ ...state, deviceEnter: true }));
      setTimeout(() => {
        setDeviceState(state => ({ ...state, deviceActive: true }));
      }, 0);
    }
  }, [visible, deviceEnter, loading]);

  useEffect(() => {
    if (solved) {
      sounds.success();
    }
  }, [solved]);

  function onValveClick(n: 1 | 2 | 3 | 4) {
    if (redOpen) {
      sounds.knock();
    } else {
      incrementRotation(n);
    }
  }

  function playCombination() {
    setPlaying([true, false, false]);
    if (timeoutHandle) {
      clearTimeout(timeoutHandle);
    }
    playSound(selectedWaveform1);
    setTimeoutHandle(setTimeout(() => {
      setPlaying([false, true, false]);
      playSound(selectedWaveform2);
      setTimeoutHandle(setTimeout(() => {
        setPlaying([false, false, true]);
        playSound(selectedWaveform3);
        setTimeoutHandle(setTimeout(() => {
          setPlaying([false, false, false]);
        }, 1000));
      }, 1000));
    }, 1000));
  }

  const OnOffButton = useCallback(() => (
    <img
      key="onOffButton"
      src={blueReadyToOpen ? open : closed}
      className={style.onOffButton}
      alt={blueReadyToOpen ? 'on' : 'off'}
      onClick={() => {
        if (!isBlueTeam && !blueReadyToOpen) {
          setBlueReadyToOpen();
          sounds.success();
        } else {
          sounds.knock();
        }
      }}
    />
    // eslint-disable-next-line
  ), [blueOpen, isBlueTeam, blueReadyToOpen]);

  const Handle = () => (
    <div
      className={style.handle}
      onClick={() => {
        if (pieSolved) {
          sounds.success();
          openRedDevice();
        } else {
          sounds.error();
        }
      }}
    />
  );

  const BlockedButton = useCallback(() => (
    <div className={classnames(style.blocked, blueReadyToOpen && style.unblocked)}>
      <img className={style.buttonUnblocked} src={buttonUnblocked} alt="buttonUnblocked" />
      {blueReadyToOpen && (
        <img
          onClick={() => {
            openBlueDevice();
            sounds.success();
          }}
          className={style.buttonUnblockedActive}
          src={buttonUnblockedActive}
          alt="buttonUnblockedActive"
        />
      )}
    </div>
    // eslint-disable-next-line
  ), [blueReadyToOpen]);

  return deviceEnter ? (
    <GameItem
      key="Waveform device"
      iconSrc={isOpen ? deviceOpenBlue : deviceClosed}
      iconStyle={{ left: '40%', top: '-7%', width: '34%', opacity: deviceActive ? 1 : 0, transition: 'opacity 1500ms' }}
      canvas={false}
      name="l5Waveform"
      description="What is this?"
      hints={isBlueTeam ? hintsBlue : hintsRed}
      onReset={() => setDeviceData({ blueOpen: !blueOpen, redOpen: !redOpen })}
      iconClassName={attention ? 'animate__animated animate__tada' : ''}
      onClick={() => setWaveformOpened()}
    >
      <img src={isOpen ? deviceOpen : deviceClosed} alt="Waveform Device" className={style.device} />
      {!isOpen && <Valves isBlueTeam={isBlueTeam} onValveClick={onValveClick} r1={r1} r2={r2} r3={r3} r4={r4} />}
      {isOpen && !isBlueTeam && <OnOffButton />}
      {!isOpen && !isBlueTeam && <Handle />}
      {!isOpen && isBlueTeam && <BlockedButton />}
      {isOpen && (
        <Inside
          isBlueTeam={isBlueTeam}
          playCombination={playCombination}
          selectedWaveform1={selectedWaveform1}
          selectedWaveform2={selectedWaveform2}
          selectedWaveform3={selectedWaveform3}
          setSelectedWaveform={setSelectedWaveform}
          solved={solved}
          playing0={playing0}
          playing1={playing1}
          playing2={playing2}
          dL0={dL0}
          dL1={dL1}
          dL2={dL2}
          dR0={dR0}
          dR1={dR1}
          dR2={dR2}
          setDoorOpen={setDoorOpen}
        />
      )}
    </GameItem>
  ) : <></>;
};
