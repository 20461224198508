import slice1 from './img/slice1.png';
import slice2 from './img/slice2.png';
import slice3 from './img/slice3.png';
import slice4 from './img/slice4.png';
import slice5 from './img/slice5.png';
import slice6 from './img/slice6.png';
import slice7 from './img/slice7.png';
import slice8 from './img/slice8.png';
import slice9 from './img/slice9.png';
import slice10 from './img/slice10.png';
import slice11 from './img/slice11.png';
import slice12 from './img/slice12.png';
import slice13 from './img/slice13.png';
import slice14 from './img/slice14.png';
import slice15 from './img/slice15.png';
import slice16 from './img/slice16.png';
import slice17 from './img/slice17.png';
import slice18 from './img/slice18.png';
import slice19 from './img/slice19.png';
import slice20 from './img/slice20.png';
import slice21 from './img/slice21.png';
import slice22 from './img/slice22.png';
import slice23 from './img/slice23.png';
import slice24 from './img/slice24.png';
import slice25 from './img/slice25.png';
import slice26 from './img/slice26.png';
import slice27 from './img/slice27.png';
import slice28 from './img/slice28.png';
import slice29 from './img/slice29.png';
import slice30 from './img/slice30.png';
import slice31 from './img/slice31.png';
import slice32 from './img/slice32.png';
import slice33 from './img/slice33.png';
import slice34 from './img/slice34.png';
import slice35 from './img/slice35.png';
import slice36 from './img/slice36.png';
import slice37 from './img/slice37.png';
import slice38 from './img/slice38.png';
import slice39 from './img/slice39.png';
import slice40 from './img/slice40.png';
import slice41 from './img/slice41.png';
import slice42 from './img/slice42.png';
import slice43 from './img/slice43.png';
import slice44 from './img/slice44.png';
import slice45 from './img/slice45.png';
import slice46 from './img/slice46.png';
import slice47 from './img/slice47.png';
import slice48 from './img/slice48.png';
import slice49 from './img/slice49.png';
import slice50 from './img/slice50.png';
import slice51 from './img/slice51.png';
import slice52 from './img/slice52.png';

export const slices = [
  slice1,
  slice2,
  slice3,
  slice4,
  slice5,
  slice6,
  slice7,
  slice8,
  slice9,
  slice10,
  slice11,
  slice12,
  slice13,
  slice14,
  slice15,
  slice16,
  slice17,
  slice18,
  slice19,
  slice20,
  slice21,
  slice22,
  slice23,
  slice24,
  slice25,
  slice26,
  slice27,
  slice28,
  slice29,
  slice30,
  slice31,
  slice32,
  slice33,
  slice34,
  slice35,
  slice36,
  slice37,
  slice38,
  slice39,
  slice40,
  slice41,
  slice42,
  slice43,
  slice44,
  slice45,
  slice46,
  slice47,
  slice48,
  slice49,
  slice50,
  slice51,
  slice52
];
