import { db, useFirestoreQuery } from '../../../firebase';
import { useAccessCode } from '../../../state';

export interface WaveformState {
  visible: boolean;
  loading: boolean;
  opened: boolean; // was the waveform opened at least once?

  r1: number; // valve/item turn radius (1 = 90deg)
  r2: number;
  r3: number;
  r4: number;
  blueOpen: boolean;
  blueReadyToOpen: boolean;
  redOpen: boolean;

  // open sliding doors
  dL0: boolean;
  dL1: boolean;
  dL2: boolean;
  dR0: boolean;
  dR1: boolean;
  dR2: boolean;
}

export const waveformInitialState: WaveformState = {
  loading: true,
  visible: false,
  opened: false,
  r1: 0,
  r2: 0,
  r3: 0,
  r4: 0,
  blueOpen: false,
  blueReadyToOpen: false,
  redOpen: false,
  dL0: false,
  dL1: false,
  dL2: false,
  dR0: false,
  dR1: false,
  dR2: false
};

type WaveformResult = [WaveformState, {
  incrementRotation: (item: 1 | 2 | 3 | 4) => void;
  openBlueDevice: () => void;
  openRedDevice: () => void;
  setBlueReadyToOpen: () => void;
  setDoorOpen: (door: 'L0' | 'L1' | 'L2' | 'R0' | 'R1' | 'R2', isOpen: boolean) => void;
  showWaveformDevice: () => void;
  setDeviceData: (state: Partial<WaveformState>) => void;
  setWaveformOpened: () => void;
}];

export function useWaveform(): WaveformResult {
  const [{ code, loading }] = useAccessCode();

  const { data, status } = useFirestoreQuery<WaveformState>(
    !loading && db.collection('game')
      .doc(code)
      .collection('waveform')
      .doc('state')
  );

  const waveformState = { ...waveformInitialState, ...(data || {}), ...{ loading: loading || status === 'loading' } };

  function incrementRotation(item: 1 | 2 | 3 | 4) {
    const toUpdate: Partial<WaveformState> = {};
    // I know switch is unnecessary, but TS was funky here. Quick and dirty workaround
    switch (item) {
      case 1:
        toUpdate.r1 = waveformState.r1 + 1;
        break;
      case 2:
        toUpdate.r2 = waveformState.r2 + 1;
        break;
      case 3:
        toUpdate.r3 = waveformState.r3 + 1;
        break;
      case 4:
        toUpdate.r4 = waveformState.r4 + 1;
        break;
      default:
        break;
    }

    db.collection('game')
      .doc(code)
      .collection('waveform')
      .doc('state')
      .set(toUpdate, { merge: true });
  }

  function openRedDevice() {
    db.collection('game')
      .doc(code)
      .collection('waveform')
      .doc('state')
      .set({ redOpen: true }, { merge: true });
  }

  function openBlueDevice() {
    db.collection('game')
      .doc(code)
      .collection('waveform')
      .doc('state')
      .set({ blueOpen: true }, { merge: true });
  }

  function setBlueReadyToOpen() {
    db.collection('game')
      .doc(code)
      .collection('waveform')
      .doc('state')
      .set({ blueReadyToOpen: true }, { merge: true });
  }

  function setDoorOpen(door: 'L0' | 'L1' | 'L2' | 'R0' | 'R1' | 'R2', isOpen: boolean) {
    const update: any = {};
    update[`d${door}`] = isOpen;
    db.collection('game')
      .doc(code)
      .collection('waveform')
      .doc('state')
      .set(update, { merge: true });
  }

  function showWaveformDevice() {
    db.collection('game')
      .doc(code)
      .collection('waveform')
      .doc('state')
      .set({ visible: true }, { merge: true });
  }

  function setDeviceData(state: Partial<WaveformState>) {
    db.collection('game')
      .doc(code)
      .collection('waveform')
      .doc('state')
      .set(state, { merge: true });
  }

  function setWaveformOpened() {
    db.collection('game')
      .doc(code)
      .collection('waveform')
      .doc('state')
      .set({ opened: true }, { merge: true });
  }

  return [waveformState, {
    incrementRotation,
    openBlueDevice,
    openRedDevice,
    setDoorOpen,
    showWaveformDevice,
    setDeviceData,
    setBlueReadyToOpen,
    setWaveformOpened
  }];
}
