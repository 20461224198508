import React, { useState } from 'react';
import { Button } from 'antd';

import { Beaker, Color, beakerImages } from './Beaker';
import { BeakerDropzone, beakerDropzoneImages } from './BeakerDropzone';
import { CustomDragLayer } from './CustomDragLayer';
import { useCurrentPlayer } from '../../../state';
import { GameItem, sounds } from '../../../components';

import style from './Beakers.module.css';

export const beakersImages = [
  ...beakerImages,
  ...beakerDropzoneImages
];

const hints = [
  'This puzzle requires several pieces to complete, the mixing flasks from both teams, the half of a periodic table found in each teams’ box and the power device itself.',
  'Use the periodic table to work out which elements are in your flasks.',
  'Input the colours that come out from mixing the colours linked to each point in the power device',
  'The colours you get should come out as dark blue, dark green, orange, dark violet'
];

function mixColors(color1: Color, color2: Color): Color {
  const r = (color1[0] + color2[0]) / 2;
  const g = (color1[1] + color2[1]) / 2;
  const b = (color1[2] + color2[2]) / 2;
  return [r, g, b];
}

function toCSS(color: Color) {
  return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
}

export const Beakers = () => {
  const [{ currentPlayer: { team } }] = useCurrentPlayer();
  const isBlueTeam = team === 'blue';
  const [color, setColor] = useState<Color>([255, 255, 255]);

  function onDrop(newColor: Color) {
    sounds.potion();
    const isStartingColor = color[0] === 255 && color[1] === 255 && color[2] === 255;
    if (isStartingColor) {
      setColor(newColor);
    } else {
      setColor(mixColors(newColor, color));
    }
  }

  const reset = () => setColor([255, 255, 255]);

  return (
    <GameItem
      iconStyle={{ left: '50%', top: '48%', width: '43%', height: '26%' }}
      canvas={false}
      name="l4Beakers"
      description="Looks like it's time to mix it up!"
      hints={hints}
      speech={isBlueTeam ? 'stefanLevel4Flasks' : 'allyLevel4flasks'}
    >
      <BeakerDropzone beakerColor={toCSS(color)} />
      <CustomDragLayer />
      <div className={style.beakerContainer}>
        <Beaker name="yellow" onDrop={onDrop} isBlueTeam={isBlueTeam} />
        <Beaker name="red" onDrop={onDrop} isBlueTeam={isBlueTeam} />
        <Beaker name="white" onDrop={onDrop} isBlueTeam={isBlueTeam} />
        <Beaker name="blue" onDrop={onDrop} isBlueTeam={isBlueTeam} />
      </div>
      <div className={style.reset}>
        <Button onClick={reset}>Reset</Button>
      </div>
    </GameItem>
  );
};
