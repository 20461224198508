import * as _ from 'lodash';
import { db, useFirestoreQuery } from '../../../firebase';
import { sounds, speech } from '../../../components';
import { useAccessCode } from '../../../state';

type SoundInput = 'dolphin'|'seagulls'|'sealions'|'whale';

type SoundDeviceState = {
  success: boolean;
  input: SoundInput[];
};

const solution = ['dolphin', 'whale', 'seagulls', 'sealions', 'whale', 'seagulls'];

export const initialState: SoundDeviceState = {
  success: false,
  input: []
};

function play(sound: SoundInput | 'success' | 'error') {
  switch (sound) {
    case 'dolphin':
      sounds.dolphin();
      break;
    case 'seagulls':
      sounds.seagulls();
      break;
    case 'sealions':
      sounds.sealions();
      break;
    case 'whale':
      sounds.whale();
      break;
    case 'success':
      sounds.success();
      break;
    default:
      sounds.error();
      break;
  }
}

type AnimalDeviceResult = [SoundDeviceState, (sound: SoundInput) => void];

export function useAnimalDevice(): AnimalDeviceResult {
  const [{ code }] = useAccessCode();
  const { data } = useFirestoreQuery<{ input: string; success: boolean }>(
    db.collection('game')
      .doc(code)
      .collection('animalDevice')
      .doc('state')
  );

  const input: SoundInput[] = JSON.parse(data?.input || JSON.stringify([]));

  function addSoundInput(newInput: SoundInput) {
    if (data?.success) return;
    play(newInput);
    let updatedInput = [...input, newInput];
    const isValidSolution = _.isEqual(updatedInput, solution);
    if (isValidSolution) {
      setTimeout(() => play('success'), 1000);
      setTimeout(() => speech.say('ckLevel4AudioDevice', { force: true }), 2000);
    }
    if (!isValidSolution && updatedInput.length >= 6) {
      updatedInput = [];
      setTimeout(() => play('error'), 1000);
    }
    db.collection('game')
      .doc(code)
      .collection('animalDevice')
      .doc('state')
      .set({
        input: JSON.stringify(updatedInput),
        success: isValidSolution
      });
  }

  return [{ success: !!data?.success, input }, addSoundInput];
}
