import React, { useState, useEffect } from 'react';
import { GameItem } from '../../../components';

import flashlightOn from './img/flashlightOn.png';
import flashlightOff from './img/flashlightOff.png';

import style from './Flashlight.module.css';

export const flashlightImages = [
  flashlightOn,
  flashlightOff
];

const MORSE = '--...';

const LONG_BLINK = 1000;
const LONG_PAUSE = 2000;
const SHORT_BLINK = 400;
const SHORT_PAUSE = 200;

const hints = [
  'This puzzle requires this flashlight and information found in the newspaper in Ally\'s team\'s box to complete.',
  'The light is flashing a code, its Morse code which can be deciphered using the newspaper.',
  'The flashlight is blinking in the following sequence _ _ . . . which gives the number 7.'
];

function transmitMorse(str: string, setLight: (l: boolean) => void) {
  return new Promise((resolve) => {
    str.split('').reduce((acc, letter, i) => {
      const isLast = i + 1 === str.length;
      const blinkTime = letter === '.' ? SHORT_BLINK : LONG_BLINK;
      const begin = acc.short * SHORT_BLINK + acc.long * LONG_BLINK + i * SHORT_PAUSE;

      // enable light
      setTimeout(() => setLight(true), begin);

      // disable light
      setTimeout(() => setLight(false), begin + blinkTime);

      if (isLast) {
        setTimeout(() => resolve(), begin + blinkTime + LONG_PAUSE);
      }

      return {
        ...acc,
        [letter === '.' ? 'short' : 'long']: acc[letter === '.' ? 'short' : 'long'] + 1
      };
    }, { long: 0, short: 0 });
  });
}

export const Flashlight = () => {
  const [light, setLight] = useState(false);

  useEffect(() => {
    (async function rememberToTransmitMorse() {
      await transmitMorse(MORSE, setLight);
      rememberToTransmitMorse();
    }());
  }, []);

  return (
    <GameItem
      iconStyle={{ left: '54%', top: '88%', width: '30%', height: '15%', transform: 'rotate(8deg)' }}
      canvas={false}
      name="l4Flashlight"
      description="What use is a blinking flashlight? Is it broken?"
      hints={hints}
      speech="stefanLevel4Torch"
    >
      <>
        <img
          key="flashlight-on"
          src={flashlightOn}
          alt="flashlight-on"
          className={style.flashlight}
          style={{ opacity: light ? 1 : 0 }}
        />
        <img
          key="flashlight-off"
          src={flashlightOff}
          alt="flashlight-off"
          className={style.flashlight}
          style={{ opacity: light ? 0 : 1 }}
        />
      </>
    </GameItem>
  );
};
