import React from 'react';

import { Input, Form, Button } from 'antd';
import WaitingRoom from './WaitingRoom';
import { MagicFrame } from '../components';
import { useAccessCode, useDebugMode } from '../state';

import style from './AccessCode.module.css';

import chestOpens from './assets/chestOpens.png';

const AccessCode = () => {
  useDebugMode(); // enable debug mode if necessary
  const [{ loading, code }, { setCode }] = useAccessCode();

  if (code) {
    return <WaitingRoom />;
  }

  return (
    <MagicFrame className={style.page}>
      <div className={style.chestWrapper}>
        <img src={chestOpens} className={style.chest} alt="Chest" />
      </div>
      <h1 className={style.escapeRoomHeader}>
        Unboxing the mind <br />
        of a Cryptic Killer
      </h1>

      <Form
        layout="horizontal"
        onFinish={({ accessCode }) => {
          setCode(accessCode.toLowerCase());
        }}
        initialValues={{ accessCode: code }}
      >
        <Form.Item
          className={style.accessCodeInput}
          name="accessCode"
          rules={[{
            required: true,
            message: 'Please input the access code'
          }]}
        >
          <Input size="large" autoFocus autoComplete="off" placeholder="Please enter License Key here" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" loading={loading} size="large" htmlType="submit">Continue</Button>
        </Form.Item>
        <p>Don't have a code? Please visit our website <a href="https://elevenpuzzles.com">https://elevenpuzzles.com</a></p>
      </Form>
    </MagicFrame>
  );
};

export default AccessCode;
