import { functions } from '../firebase';

const sendError = functions.httpsCallable('sendError');

export const logger = {
  error: async(object: any) => {
    console.error(object);
    try {
      await sendError(object);
    } catch (err) {
      console.log('err:', err);
    }
  }
};
