import React, { FunctionComponent, useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useStoreState, useStoreActions } from 'easy-peasy';
import CanvasDraw from 'react-canvas-draw';
import { isUndefined } from 'lodash';
import { CloseIcon, Controls, speech } from '..';
import { Hints } from './Hints';
import { useCurrentPlayer, useDebugMode } from '../../state';

import style from './GameModal.module.css';

interface GameModalProps {
  visible: boolean;
  onCloseModal: () => void;
  name: string;
  canvas?: boolean;
  canvasColor?: string;
  description?: string;
  imageSrc?: string;
  destroyOnClose?: boolean;
  hints?: React.ReactNode[];
  onReset?: () => void;
  speech?: string;
}

export const GameModal: FunctionComponent<GameModalProps> = (props) => {
  let loadedCanvas: CanvasDraw | null = null;
  const { canvas, name, onCloseModal, description, imageSrc, visible } = props;
  const [showHints, setShowHints] = useState(false);
  const savedDrawings = useStoreState(state => state.canvas.drawings);
  const windowSize = useStoreState(state => state.resize);
  const saveDrawing = useStoreActions((state: any) => state.canvas.set);
  const [, { logAction }] = useCurrentPlayer();
  const [{ debugMode }] = useDebugMode();
  const hints = props.hints || [];

  useEffect(() => {
    if (!name || !loadedCanvas) return;
    if (savedDrawings[name]) {
      setTimeout(() => {
        // no idea why I need timeout, only way to make it work
        loadedCanvas && loadedCanvas.loadSaveData(savedDrawings[name], true);
      }, 100);
    }
    // eslint-disable-next-line
  }, [visible, loadedCanvas, name, visible, showHints]);

  useEffect(() => {
    // save canvas data every second
    const interval = setInterval(() => {
      if (name && loadedCanvas) {
        const drawing = loadedCanvas.getSaveData();
        saveDrawing({ key: name, drawing });
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [name, loadedCanvas, showHints, visible, saveDrawing]);

  function onClose() {
    if (showHints) {
      logAction('hide-hints', props.name);
      return setShowHints(false);
    }

    if (name && loadedCanvas) {
      const drawing = loadedCanvas.getSaveData();
      saveDrawing({ key: name, drawing });
    }
    return onCloseModal();
  }

  function onClearCanvas() {
    if (loadedCanvas) {
      loadedCanvas.clear();
      logAction('clear-canvas', props.name);
    }
  }

  function onUndoCanvas() {
    if (loadedCanvas) {
      loadedCanvas.undo();
    }
  }

  return (
    <>
      <Modal
        transitionName=""
        maskTransitionName=""
        className="transparent"
        visible={visible}
        onCancel={onClose}
        width="100%"
        mask={true}
        maskStyle={{ backgroundColor: 'rgba(40, 40, 40, 0.8)' }}
        maskClosable={true}
        closable={true}
        title={showHints ? 'Do you need a hint?' : description}
        closeIcon={<CloseIcon />}
        footer={false}
        style={{ top: 30 }}
        destroyOnClose={isUndefined(props.destroyOnClose) ? true : props.destroyOnClose}
        bodyStyle={{ padding: 0 }}
      >
        <>
          {canvas && !showHints && (
            <CanvasDraw
              className={style.canvas}
              backgroundColor="transparent"
              brushColor={props.canvasColor || '#20D000'}
              hideGrid={true}
              hideInterface={true}
              lazyRadius={1}
              brushRadius={1.5}
              canvasHeight={windowSize.height}
              canvasWidth={windowSize.width - 100}
              ref={(cnvs) => {
                loadedCanvas = cnvs;
              }}
            />
          )}
          <div className={style.modalBody}>
            {imageSrc && !showHints && (
              <img
                src={imageSrc}
                alt="Background img"
                className={style.image}
              />
            )}
            {!showHints && props.children}
            {showHints && (<Hints itemName={name} hints={hints} />)}
          </div>
          <Controls
            hint={!showHints && hints.length > 0}
            onHint={() => {
              setShowHints(!showHints);
              logAction(showHints ? 'hide-hints' : 'show-hints', props.name);
            }}
            speech={!showHints && !!props.speech}
            onSpeech={() => speech.say(props.speech!, { force: true })}
            clear={canvas && !showHints}
            onClear={onClearCanvas}
            undo={canvas && !showHints}
            onUndo={onUndoCanvas}
            reset={debugMode && !!props.onReset}
            onReset={props.onReset}
          />
        </>
      </Modal>
    </>
  );
};
