import React from 'react';
import { Card, Result, Button } from 'antd';

export const ErrorPage = () => (
  <Card style={{ margin: 40 }}>
    <Result
      status="500"
      title="500"
      subTitle="Sorry, something went wrong."
      extra={<Button type="primary" onClick={() => window.location.reload()}>Try again</Button>}
    />
  </Card>
);
